import api from './api'
import { LoginRequired } from '../errors'

class StaticService {
  loadMccCodes () {
    return api
      .get('/static/mcc', {})
      .then(response => {
        return response
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }
}

export default new StaticService()
