import axios from 'axios'
import TokenService from './token'
import { LoginRequired } from '../errors'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 5000
})

api.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if (originalConfig.url !== '/login' && originalConfig.url !== '/refreshtoken' && err.response) {
      if (err.response.status === 401 && !originalConfig.retry) {
        originalConfig.retry = true

        if (!originalConfig.counter) {
          originalConfig.counter = 0
        } else {
          originalConfig.counter++
        }

        try {
          const response = await api.post('/refreshtoken', {
            RefreshToken: TokenService.getLocalRefreshToken()
          })
          TokenService.updateLocalAccessToken(response.data.Token)
          TokenService.updateLocalRefreshToken(response.data.RefreshToken)

          return api(originalConfig)
        } catch (error) {
          TokenService.removeUser()
          window.location.replace('http://' + process.env.REACT_APP_FRONTEND_URL + '/#/login')
          throw LoginRequired('Refresh Token expired')
        }
      }
    }

    return Promise.reject(err)
  }
)

export default api
