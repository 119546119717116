import React, { useState } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Stack, getTheme } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { IconButton } from '@fluentui/react/lib/Button'
import EmailAdapterService from '../services/emailadapters'
import { Email } from './Email'

export function EmailAdapter (props) {
  const [rerunning, setRerunning] = useState(false)

  const theme = getTheme()

  const categoryNameClass = mergeStyles({
    backgroundColor: theme.palette.white,
    boxShadow: theme.effects.elevation4,
    padding: '20px'
  })

  const rerunFailedEmails = () => {
    const id = props.data.EmailAdapterId
    setRerunning(true)
    EmailAdapterService.rerunFailedEmails(id).then((response) => {
      setRerunning(false)
    })
  }

  const onEmailRerunClicked = (index, displayLock) => {
    const id = props.data.EmailAdapterId
    displayLock(true)
    EmailAdapterService.rerunFailedEmail(id, index).then((response) => {
      displayLock(false)
      props.requestRefresh()
    })
  }

  return (
    <Stack
      key={props.data.Id}
      className={categoryNameClass}
      vertical
    >
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <span>
          {props.data.EmailAddress}
          <IconButton iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(props.data.EmailAddress)} />
        </span>
        <Stack horizontal verticalAlign='center'>
          <span>Number of failed emails: {props.data.EmailsFailedOnParsing.length}</span>
          {!rerunning && <IconButton iconProps={{ iconName: 'Rerun' }} onClick={() => rerunFailedEmails()} />}
          {rerunning && <Spinner size={SpinnerSize.small} />}
        </Stack>
        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => props.onDeleteAdapter(props.data.Id)} />
      </Stack>
      <Stack vertical tokens={{ childrenGap: 10 }}>
        {props.data.EmailsFailedOnParsing.map((email, index) => (
          <Email key={email.Id} data={email} onRerunClicked={onEmailRerunClicked} />
        ))}
      </Stack>
    </Stack>
  )
}
