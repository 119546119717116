import React, { useState } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Stack, getTheme } from '@fluentui/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { NeutralColors } from '@fluentui/theme'
import { useBoolean } from '@fluentui/react-hooks'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'

export function Email (props) {
  const theme = getTheme()
  const [isShownFull, { toggle: toggleIsShownFull }] = useBoolean(false)
  const [rerunning, setRerunning] = useState(false)

  const backgroundClass = mergeStyles({
    backgroundColor: NeutralColors.gray10,
    boxShadow: theme.effects.elevation4,
    width: '100%',
    maxHeight: (isShownFull ? null : '200px'),
    overflow: 'hidden',
    padding: '20px'
  })

  return (
    <Stack vertical className={backgroundClass}>
      <Stack horizontal horizontalAlign='space-between'>
        <span>ID: {props.data.Id}</span>
        <div>
          {!rerunning && <IconButton iconProps={{ iconName: 'Rerun' }} onClick={() => props.onRerunClicked(props.data.Id, setRerunning)} />}
          {rerunning && <Spinner size={SpinnerSize.small} />}
          <IconButton iconProps={{ iconName: isShownFull ? 'ChevronUp' : 'ChevronDown' }} onClick={toggleIsShownFull} />
        </div>
      </Stack>
      <div dangerouslySetInnerHTML={{ __html: props.data.html.replace(/\n/g, '<br />') }} ></div>
    </Stack>
  )
}
