import React, { useState } from 'react'
import { Stack, mergeStyles } from '@fluentui/react'
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { useBoolean } from '@fluentui/react-hooks'

import { Dropdown } from '@fluentui/react/lib/Dropdown'
import { FontSizes } from '@fluentui/theme'

export function CategoryBar (props) {
  const [isSelectorHidden, { toggle: toggleIsSelectorHidden }] = useBoolean(true)

  const [category, setCategory] = useState(null)

  const getFeatheredGradient = (blocks, featherNumber) => {
    if (featherNumber < 0 || featherNumber > 1) {
      throw new Error('featherNumber out of bounds: must be be in the range 0 to 1')
    }
    // A full feather meets in the middle of the color block
    const feather = featherNumber / 2

    const totalWeight = 100// blocks.reduce((sum, block) => sum + Number(block.value), 0);

    const gradients = []
    let gradientStop = 0
    let visualStop = 0
    blocks.forEach((block, index) => {
      const blockColor = block.color
      const blockWeightAsPercent = (block.value / totalWeight) * 100
      const blockFeather = blockWeightAsPercent * feather

      // Keep current gradientStop for the start of the block
      if (index === 0) {
        visualStop = 0
      } else {
        visualStop = gradientStop + blockFeather
      }
      gradients.push(`${blockColor} ${visualStop}%`)

      // Increment the gradientStop for the end of the block
      gradientStop += blockWeightAsPercent
      if (index === blocks.length - 1) {
        visualStop = 100
      } else {
        visualStop = gradientStop - blockFeather
      }
      gradients.push(`${blockColor} ${visualStop}%`)
    })

    return `linear-gradient(to bottom, ${gradients.join(',')})`
  }

  let background = props.color ? props.color : getFeatheredGradient(props.gradient, 0)
  if (props.show && props.colorOnShow) {
    background = props.colorOnShow
  }

  const categoryColorBarClass = mergeStyles({
    width: (props.show || !isSelectorHidden) && (props.categoryUpdateHandle !== undefined) ? props.width : '5px',
    background,
    maxWidth: '100px',
    transition: 'width 300ms, background 300ms',
    textAlign: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    color: (props.show || !isSelectorHidden) ? 'rgba(0,0,0,255)' : 'rgba(0,0,0,0)',
    whiteSpace: 'nowrap'
  })

  const contentClass = mergeStyles({
    visibility: (props.show ? 'visible' : 'hidden'),
    transition: 'visibility 300ms',
    padding: '5px'
  })

  return (
    <>
      <Stack className={categoryColorBarClass} onClick={toggleIsSelectorHidden} verticalAlign='center'>
        <span className={contentClass}>{props.name}</span>
      </Stack>
      <Dialog hidden={isSelectorHidden || (props.categoryUpdateHandle === undefined)}>
        <span style={{ fontSize: FontSizes.size16 }}>Select a category</span>
        <Dropdown
          placeholder="Select an option"
          options={props.categoriesList.map(category => {
            return { key: category.Id, text: category.Name }
          })}
          onChange={(event, option, index) => setCategory(option.key)}
        />
        <DialogFooter>
          <DefaultButton text="Cancel" onClick={toggleIsSelectorHidden} />
          <PrimaryButton text={props.applyButtonText} onClick={() => {
            props.onApply(category)
            toggleIsSelectorHidden()
          }} />
        </DialogFooter>
      </Dialog>
    </>
  )
}
