import React from 'react'
import { getTheme } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { Label } from '@fluentui/react/lib/Label'
import { Stack } from '@fluentui/react/lib/Stack'
import { mergeStyles } from '@fluentui/react/lib/Styling'

export function LoaderScreen (props) {
  const theme = getTheme()

  const backgroundClass = mergeStyles({
    backgroundColor: '#0078D4',
    height: '100%',
    width: '100%'
  })

  const textClass = mergeStyles({
    color: theme.palette.white
  })

  return (
    <Stack horizontal horizontalAlign='center' verticalAlign='center' tokens={{
      childrenGap: 20
    }} className={backgroundClass} >
      <Spinner size={SpinnerSize.large} />
      <Label className={textClass}>Loading data</Label>
    </Stack >
  )
}
