import api from './api'
import { LoginRequired } from '../errors'

class TransactionsService {
  getById (id) {
    return api
      .get('/transaction/' + id, {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  update (id, data) {
    return api
      .put('/transaction/' + id, data)
      .then(response => {
        return response
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  getAll () {
    return api
      .get('/transactions', {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  getWithPaging (beforeId) {
    if (beforeId === null) {
      return api
        .get('/transactions', {})
        .then(response => {
          return response.data
        })
        .catch(e => {
          if (e instanceof LoginRequired) {
            ; // Silently ignored since already handled in the api.js
          }
        })
    } else {
      return api
        .get('/transactions?BeforeId=' + beforeId, {})
        .then(response => {
          return response.data
        })
        .catch(e => {
          if (e instanceof LoginRequired) {
            ; // Silently ignored since already handled in the api.js
          }
        })
    }
  }
}

export default new TransactionsService()
