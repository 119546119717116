import React, { useState } from 'react'
import { Stack, mergeStyles } from '@fluentui/react'
import { NeutralColors } from '@fluentui/theme'
import { TransparentInput } from './TransparentInput'
import { Pill } from './Pill'
import { CategoryBar } from './CategoryBar'
import { IconButton } from '@fluentui/react/lib/Button'

export function Item (props) {
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(false)
  const [isItemFocusLocked, setIsItemFocusLocked] = useState(false)

  const itemBackground = mergeStyles({
    width: '100%',
    backgroundColor: isCategoryExpanded ? NeutralColors.gray20 : NeutralColors.gray10
  })

  const nextUnit = (prevUnits) => {
    switch (prevUnits) {
      case 'pcs':
        return 'liter'
      case 'liter':
        return 'kg'
      case 'kg':
        return 'pcs'
    }
  }

  const expandCategory = () => {
    if (!isItemFocusLocked) {
      setIsCategoryExpanded(true)
    }
  }

  const expandCategoryAndLock = () => {
    setIsCategoryExpanded(true)
    setIsItemFocusLocked(true)
  }

  const hideCategoryAndUnlock = () => {
    setIsItemFocusLocked(false)
    setIsCategoryExpanded(false)
  }

  const hideCategory = () => {
    if (!isItemFocusLocked) {
      setIsCategoryExpanded(false)
    }
  }

  const updateCategory = (id) => {
    props.onChange({ ...props.data, Category: id })
  }

  const category = props.categoriesList.find(category => category.Id === props.data.Category)
  const categoryName = (category !== undefined ? category.Name : 'No category')
  const categoryColor = (category !== undefined ? category.Color : 'white')

  return (
    <Stack className={itemBackground} horizontal horizontalAlign='end' verticalAlign='center' style={{ width: '100%' }} tokens={{ childrenGap: 10 }} onMouseOver={expandCategory}
      onMouseOut={hideCategory}>
      <CategoryBar
        width={'100px'}
        name={categoryName}
        color={categoryColor}
        show={isCategoryExpanded}
        categoryUpdateHandle={updateCategory}
        categoriesList={props.categoriesList}
        onApply={updateCategory}
        applyButtonText={'Apply to the item'}
      />
      <Stack.Item grow>
        <TransparentInput value={props.data.Name} type="text" style={{ textAlign: 'left', fontSize: '1em', padding: '5px', width: '100%' }} onChange={(value) => props.onChange({ ...props.data, Name: value })} onFocus={expandCategoryAndLock} onBlur={hideCategoryAndUnlock} />
      </Stack.Item>
      <Stack.Item>
        <TransparentInput value={props.data.Amount} type="number" style={{ textAlign: 'right', fontSize: '1em' }} onChange={(value) => props.onChange({ ...props.data, Amount: value })} />
      </Stack.Item>
      <Stack.Item style={{ width: 50, textAlign: 'left' }}>
        <Pill text={props.data.Units.toUpperCase()} style={{ backgroundColor: NeutralColors.white, color: NeutralColors.gray100, border: '1px solid', fontSize: 10 }} onClick={() => { props.onChange({ ...props.data, Units: nextUnit(props.data.Units) }) }} />
      </Stack.Item>
      <Stack.Item style={{ color: NeutralColors.gray80, width: 120, textAlign: 'right' }}>
        {props.data.Value > 0 && props.data.Amount !== 1 && props.data.Amount > 0 &&
          <span>{(props.data.Value / props.data.Amount).toFixed(2)} {props.currency}</span>
        }
      </Stack.Item>
      <Stack.Item style={{ color: NeutralColors.gray80, width: 40, textAlign: 'left' }}>
        {props.data.Value > 0 && props.data.Amount !== 1 && props.data.Amount > 0 &&
          <span>/ {props.data.Units.toLowerCase()}</span>
        }
      </Stack.Item>
      <Stack.Item>
        <TransparentInput value={props.data.Value} type="number" style={{ textAlign: 'right', fontSize: '1em' }} onChange={(value) => props.onChange({ ...props.data, Value: value })} />
      </Stack.Item>
      <IconButton iconProps={{ iconName: 'Delete' }} onClick={props.deleteItem} />
    </Stack>
  )
}
