import React from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Nav } from '@fluentui/react/lib/Nav'

export function Sidebar (props) {
  function _onRenderGroupHeader (group) {
    const groupHeaderClass = mergeStyles({
      textAlign: 'left'
    }, 'ms-fontSize-24')

    return <div className={groupHeaderClass}>{group.name}</div>
  }

  const navStyles = { root: { width: 300, position: 'absolute', right: 0, paddingRight: '30px', paddingTop: '30px' } }
  const navLinkGroups = [
    {
      name: 'Operations',
      links: [
        {
          key: 'Summary',
          name: 'Summary',
          url: '#/operations/summary'
        },
        {
          key: 'Reports',
          name: 'Reports',
          url: '#/operations/reports'
        },
        {
          key: 'ReceiptsManagement',
          name: 'Upload receipts scans',
          url: '#/operations/receipts'
        }
      ]
    },
    {
      name: 'Settings',
      links: [
        {
          key: 'Categories',
          name: 'Categories',
          url: '#/settings/categories'
        }
      ]
    },
    {
      name: 'Banks',
      links: [
        {
          key: 'Accounts',
          name: 'Accounts',
          url: '#/banks/accounts'
        },
        {
          key: 'Cards',
          name: 'Cards',
          url: '#/banks/cards'
        }
      ]
    },
    {
      name: 'Adapters',
      links: [
        {
          key: 'Email',
          name: 'Email',
          url: '#/adapters/email'
        }
      ]
    }
  ]

  return (
        <Nav styles={navStyles} groups={navLinkGroups} onRenderGroupHeader={_onRenderGroupHeader} />
  )
}
