import api from './api'
import { LoginRequired } from '../errors'

class EmailAdapterService {
  delete (id) {
    return api
      .delete('/adapter/email/' + id, {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  create () {
    return api
      .post('/adapter/email', {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  getAll () {
    return api
      .get('/adapter/emails', {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  rerunFailedEmails (id) {
    return api.post('/adapter/email/' + id, {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  rerunFailedEmail (id, emailIndex) {
    return api.post('/adapter/email/' + id, {
      EmailIndex: emailIndex
    })
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }
}

export default new EmailAdapterService()
