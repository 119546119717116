import React from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'

export function TransparentInput (props) {
  const inputClass = mergeStyles({
    background: 'none',
    border: 'none',
    outline: 'none',
    textAlign: props.textAlign,
    width: '7em'
  },
  {
    '::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  }, props.style
  )

  return (
        <input
            className={inputClass}
            value={props.value}
            type={props.type}
            onChange={(e) => props.onChange(e.target.value)}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
        />
  )
}
