import React, { useState, useEffect } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Stack } from '@fluentui/react'
import { Transaction } from './Transaction'
import { NeutralColors } from '@fluentui/theme'
import TransactionsService from './services/transactions'
import CategoriesService from './services/categories'
function isThisWeek (date) {
  const todayObj = new Date()
  const todayDate = todayObj.getDate()
  const todayDay = todayObj.getDay()

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay))

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek)
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek
}

function isThisMonth (date) {
  const today = new Date()
  return today.getMonth() === date.getMonth()
}

export function Operations (props) {
  const [isfetching, setIsFetching] = useState(true)
  const [data, setData] = useState([])
  const [categoriesList, setCategoriesList] = useState(null)
  const [isAllDataFetched, setIsAllDataFetched] = useState(false)
  const [page, setPage] = useState(0)

  useEffect(() => {
    TransactionsService.getWithPaging(null).then((data) => {
      setData(data)
      setIsFetching(false)
    })

    CategoriesService.getAll().then((data) => {
      setCategoriesList(data)
    })
  }, [])

  const periodHeaderClass = mergeStyles({
    fontSize: 18,
    color: NeutralColors.gray50,
    fontWeight: 'light',
    marginBottom: 10
  })

  let isThisWeekHeaderAdded = false
  let isThisMonthHeaderAdded = false
  let isRemainingHeaderAdded = false
  const list = []
  if (categoriesList && data) {
    data.forEach(transaction => {
      const date = new Date(transaction.Timestamp)
      if (isThisWeek(date) && !isThisWeekHeaderAdded) {
        list.push(<span className={periodHeaderClass}>This week</span>)
        isThisWeekHeaderAdded = true
      }
      if (!isThisWeek(date) && !isThisMonthHeaderAdded) {
        list.push(<span className={periodHeaderClass}>Previous operations this month</span>)
        isThisMonthHeaderAdded = true
      }
      if (!isThisMonth(date) && !isRemainingHeaderAdded) {
        list.push(<span className={periodHeaderClass}>All previous operations</span>)
        isRemainingHeaderAdded = true
      }
      list.push(<Transaction data={transaction} categoriesList={categoriesList} />)
    })
  }

  const rowClass = mergeStyles({
    height: 'calc(100% - 50px)'
  }, 'ms-Grid-row')

  const workspaceContent = mergeStyles({
    overflow: 'hidden scroll',
    height: '100%',
    paddingLeft: '20px !important'
  }, 'ms-Grid-col', 'ms-lg8')

  const workspaceMenuClass = mergeStyles({
    height: '100%',
    paddingLeft: '20px !important'
  }, 'ms-Grid-col', 'ms-lg4')

  const onScrollHandle = (event) => {
    const scrollPosition = event.currentTarget.scrollTop / (event.currentTarget.scrollHeight - event.currentTarget.clientHeight)
    if (scrollPosition > 0.7 && !isfetching && !isAllDataFetched) {
      setIsFetching(true)
      fetchMoreTransactions(page + 1)
    }
  }

  const fetchMoreTransactions = (page) => {
    TransactionsService.getWithPaging(page).then((fetchedData) => {
      if (fetchedData.length === 0) {
        setIsAllDataFetched(true)
        return
      }
      let newData = data
      newData = newData.concat(fetchedData)
      setData(newData)
      setPage(page)
      setIsFetching(false)
    })
  }

  return (
    <div className={rowClass}>
      <div className={workspaceContent} onScroll={onScrollHandle}>
        <Stack enableScopedSelectors vertical>
          {list}
        </Stack>
      </div>
      <div className={workspaceMenuClass}></div>
    </div>
  )
}
