import api from './api'
import { LoginRequired } from '../errors'

class CategoriesService {
  getById (id) {
    return api
      .get('/category/' + id, {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  update (id, name, color, parentId, childrenIds, removeChildrenOnDelete) {
    return api
      .put('/category/' + id, {
        Name: name,
        ParentId: parentId,
        ChildrenIds: childrenIds,
        RemoveChildren: removeChildrenOnDelete,
        Color: color
      })
      .then(response => {
        return response
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  delete (id) {
    return api
      .delete('/category/' + id, {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  create (name, color, parentId, childrenIds, removeChildrenOnDelete) {
    return api
      .post('/category', {
        Name: name,
        ParentId: parentId,
        ChildrenIds: childrenIds,
        RemoveChildren: removeChildrenOnDelete,
        Color: color
      })
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }

  getAll () {
    return api
      .get('/categories', {})
      .then(response => {
        return response.data
      })
      .catch(e => {
        if (e instanceof LoginRequired) {
          ; // Silently ignored since already handled in the api.js
        }
      })
  }
}

export default new CategoriesService()
