import './index.css'
import React, { useState, useEffect } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Stack } from '@fluentui/react'
import { DefaultButton } from '@fluentui/react/lib/Button'

import { EmailAdapter } from './EmailAdapter/EmailAdapter'

import EmailAdapterService from './services/emailadapters'

export function EmailAdapters (props) {
  const [data, setData] = useState([])
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createButtonClass = mergeStyles({
    marginBottom: 20
  })

  useEffect(() => {
    EmailAdapterService.getAll().then((data) => {
      setData(data)
    })
  }, [])

  const createAdapter = () => {
    setIsCreateButtonDisabled(true)
    EmailAdapterService.create().then((response) => {
      const newData = data
      newData.push(response)
      setData(newData)
      setIsCreateButtonDisabled(false)
    })
  }

  const deleteAdapter = (index) => {
    const id = data[index].EmailAdapterId
    setIsCreateButtonDisabled(true)
    EmailAdapterService.delete(id).then((response) => {
      const newData = data
      newData.splice(index, 1)
      setData(newData)
      setIsCreateButtonDisabled(false)
    })
  }

  const onRefreshRequested = () => {
    EmailAdapterService.getAll().then((data) => {
      setData(data)
    })
  }

  return (
    <div>
      <DefaultButton text="Create" className={createButtonClass} onClick={createAdapter} disabled={isCreateButtonDisabled} />
      <Stack vertical tokens={{ childrenGap: 10 }}>
        {data.map((emailAdapter, index) => (
          <EmailAdapter key={emailAdapter.Id} data={emailAdapter} onDeleteAdapter={deleteAdapter} requestRefresh={onRefreshRequested} />
        ))}
      </Stack>
    </div>
  )
}
