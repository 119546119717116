import React, { useState } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { ThemeProvider } from '@fluentui/react/lib/Theme'
import { Stack, Text } from '@fluentui/react'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { Navigate } from 'react-router-dom'
import AuthorizationService from './services/authorization'

export function Navbar (props) {
  const [isLogged, setIsLogged] = useState(true)

  const logout = () => {
    AuthorizationService.logout()
    setIsLogged(false)
  }

  const iconClass = mergeStyles({
    fontSize: 10,
    height: 50,
    width: 50,
    margin: '0 25px',
    color: 'white'
  })
  const layerStyles = mergeStyles([
    {
      backgroundColor: '#0078D4',
      color: 'black',
      lineHeight: '70px',
      padding: '0 20px',
      opacity: '1'

    }
  ])

  const searchBoxStyles = { root: { width: 600 } }

  const blueTheme = {
    palette: {
      themePrimary: 'white',
      themeDark: '#324c4d'
    }
  }

  if (!isLogged) {
    return <Navigate to="/login" />
  }

  return (
        <ThemeProvider theme={blueTheme}>
            <div className={layerStyles}>
                <Stack enableScopedSelectors horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack nableScopedSelectors horizontal horizontalAlign="left" verticalAlign="center">
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: 'Waffle' }} styles={iconClass} onClick={props.toggleSidebarMenu} />
                        </Stack.Item>
                        <Stack.Item>
                            <Text >
                                Finapp
                            </Text>
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <SearchBox
                            styles={searchBoxStyles}
                            placeholder="Search Operations"
                            onEscape={ev => {
                              console.log('Custom onEscape Called')
                            }}
                            onClear={ev => {
                              console.log('Custom onClear Called')
                            }}
                            onChange={(_, newValue) => console.log('SearchBox onChange fired: ' + newValue)}
                            onSearch={newValue => console.log('SearchBox onSearch fired: ' + newValue)}
                        />
                    </Stack.Item>
                    <Stack enableScopedSelectors horizontal horizontalAlign="right" verticalAlign="center">
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: 'Ringer' }} styles={iconClass} />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: 'Settings' }} styles={iconClass} />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: 'Contact' }} styles={iconClass} onClick={logout} />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </div>
        </ThemeProvider >
  )
}
