import React, { useState, useEffect } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling'
import { Stack, getTheme } from '@fluentui/react'
import { FontIcon } from '@fluentui/react/lib/Icon'

import { NeutralColors } from '@fluentui/theme'
import { getDescriptionById } from './mcc'
import { Item } from './Item'
import { CategoryBar } from './CategoryBar'
import { useBoolean } from '@fluentui/react-hooks'

import TransactionsService from './services/transactions'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { Decimal } from 'decimal.js'

export function Transaction (props) {
  const [data, setData] = useState(props.data)
  const [isInitRender, setIsInitRender] = useState(true)
  const [isSaveRequired, setIsSaveRequired] = useState(false)
  const [isSaveInProgress, setIsSaveInProgress] = useState(false)
  const [isItemSectionHidden, { toggle: toggleIsItemSectionHidden }] = useBoolean(true)
  const [isCategoryToolboxHidden, setIsCategoryToolboxHidden] = useState(true)
  const [highlightValues, sethighlightValues] = useState(false)

  useEffect(() => {
    if (isInitRender) {
      setIsInitRender(false)
      return
    }
    setIsSaveRequired(true)
  }, [data])

  const theme = getTheme()

  const cardOuterClass = mergeStyles({
    backgroundColor: NeutralColors.white,
    boxShadow: theme.effects.elevation4,
    marginBottom: '0.8em',
    width: '100%'
  })

  const cardInnerClass = mergeStyles({
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'calc(100% - 3px)'
  })

  const iconClass = mergeStyleSets({
    receipt: [{ color: '#E0E0E0', fontSize: '0.8em', marginTop: '20px' }],
    showItems: [{ width: 8, height: 8 }],
    transactionType: [{ color: '#E0E0E0', fontSize: '1.2em', marginTop: '0.45em' }],
    categoryToolbox: [{ color: 'white', fontSize: '1em' }]
  })

  const valueClass = mergeStyles({
    fontSize: '1.2em',
    color: 'black',
    fontWeight: 'semibold',
    background: (highlightValues ? 'red' : 'none')
  })

  const dateAndLocationClass = mergeStyles({
    fontSize: '0.8em',
    color: '#BDBDBD'
  })

  const titleClass = mergeStyles({
    fontSize: '1em',
    color: 'black',
    textTransform: 'uppercase',
    marginTop: '3px'
  })

  const mccClass = mergeStyles({
    fontSize: '0.8em',
    color: '#BDBDBD'
  })

  const categoryClass = mergeStyles({
    fontSize: '0.8em',
    color: '#828282',
    marginTop: '5px'
  })

  const transactionOptionsClass = mergeStyles({
    paddingLeft: '15px',
    marginLeft: '25px',
    borderLeft: '2px solid',
    borderLeftColor: NeutralColors.gray20,
    float: 'right'
  })

  const itemsList = mergeStyles({
    backgroundColor: NeutralColors.gray10,
    boxShadow: 'inset 0 3px 5px -3px rgba(0,0,0,0.1)',
    padding: '15px',
    display: 'flex'
  })

  const date = new Date(data.Timestamp)
  let title = String()

  const transactionTypeToIconMap = {
    Card: 'PaymentCard',
    IncomingTransfer: 'Import',
    OutgoingTransfer: 'ImportMirrored',
    ATM: 'Money'
  }

  switch (data.TransactionType) {
    case 'Card':
      title = data.Name
      break

    case 'IncomingTransfer':
      title = data.Name
      break
    case 'OutgoingTransfer':
      title = data.Name
      break

    case 'ATM':
      title = 'ATM'
      break
  }

  const createEmptyItem = () => {
    setData(prevData => {
      return {
        ...prevData,
        Category: null,
        Items: [...data.Items, {
          Name: 'New item',
          Value: 0.0,
          Amount: 0.0,
          Units: 'pcs',
          Category: null
        }]
      }
    })
    sethighlightValues(false)
  }

  const handleItemChange = (index, newItem) => {
    sethighlightValues(false)
    const items = data.Items
    items[index] = { ...newItem, Amount: parseFloat(newItem.Amount), Value: parseFloat(newItem.Value) }
    console.log(items[index])
    setData({ ...data, Items: items })
  }

  const totalValuePerCategory = new Map()
  data.Items.forEach(item => {
    if (item.Category === undefined) {
      return
    }
    const id = item.Category
    if (!(item.Category in totalValuePerCategory)) {
      totalValuePerCategory.set(id, item.Value)
    } else {
      totalValuePerCategory[id] += item.Value
    }
  })

  const percentagePerColor = []
  totalValuePerCategory.forEach((value, id) => {
    const category = props.categoriesList.find(item => item.Id === id)
    if (category) {
      percentagePerColor.push({ color: category.Color, value: 100 * value / data.Value.Value })
    }
  })

  const categoriesNames = []

  if (data.Items.length > 0) {
    totalValuePerCategory.forEach((value, id) => {
      const category = props.categoriesList.find(item => item.Id === id)
      if (category) {
        categoriesNames.push(category.Name)
      }
    })
  } else {
    if (data.Category) {
      const category = props.categoriesList.find(item => item.Id === data.Category)
      categoriesNames.push(category.Name)
    }
  }

  const categoryColor = data.Category ? props.categoriesList.find(item => item.Id === data.Category).Color : null

  const handle = () => {

  }

  const onFocus = () => {
    setIsCategoryToolboxHidden(false)
  }

  const onBlur = () => {
    setIsCategoryToolboxHidden(true)
  }

  const onCategoryApply = (id) => {
    if (data.Items.length > 0) {
      const items = data.Items
      items.map((item, index) => (
        item.Category = id
      ))
      setData({ ...data, Category: null, Items: items })
    } else {
      const newData = data
      newData.Category = id
      setData(newData)
      saveChanges()
    }
  }

  const isItemsValueSumCorrect = () => {
    if (data.Items.length === 0) {
      return true
    }

    let sum = new Decimal(0.0)
    data.Items.forEach(item => {
      sum = sum.plus(new Decimal(item.Value))
    })

    return sum.equals(new Decimal(data.Value.Value))
  }

  const saveChanges = () => {
    if (isItemsValueSumCorrect()) {
      sethighlightValues(false)
      setIsSaveInProgress(true)
      TransactionsService.update(data.Id, data).then(response => {
        setIsSaveRequired(false)
        setIsSaveInProgress(false)
      })
    } else {
      sethighlightValues(true)
    }
  }

  const deleteItem = (index) => {
    console.log(index)
    const items = data.Items
    items.splice(0, 1)
    console.log(index, data.Items, items, data, props.data)
    setData({ ...data, Items: items })
  }

  return (
    <Stack vertical className={cardOuterClass}>
      <Stack horizontal onMouseOver={onFocus} onMouseOut={onBlur}>
        <CategoryBar
          width={'40px'}
          colorOnShow={theme.palette.themePrimary}
          name={<FontIcon iconName="Label" className={iconClass.categoryToolbox} />}
          show={!isCategoryToolboxHidden}
          color={categoryColor}
          gradient={percentagePerColor}
          categoriesList={props.categoriesList}
          categoryUpdateHandle={handle}
          onApply={onCategoryApply}
          applyButtonText={(data.Items.length > 0 ? 'Apply to all items' : 'Apply')}
        />
        <Stack enableScopedSelectors horizontal horizontalAlign="space-between" className={cardInnerClass}>
          <Stack enableScopedSelectors vertical >
          <Stack.Item className={dateAndLocationClass}>
            {date.toLocaleDateString('en-PL', { weekday: 'long' })}
             / {date.toLocaleDateString('en-PL', { dateStyle: 'long' })}
            {'Location' in data && <> / {data.Location.City}, {data.Location.Country}</>}
          </Stack.Item>
            <Stack.Item className={titleClass}>
              {'Sender' in data && <>{data.Sender} / </>}
              {'Recipient' in data && <>{data.Recipient} / </>}
              {title}
            </Stack.Item>
            <Stack.Item className={mccClass}>{'Card' in data && data.Card && getDescriptionById(parseInt(data.Card.MCC))}&nbsp;</Stack.Item>
            <Stack.Item className={categoryClass}>
              {categoriesNames.map((name, index) => (
                <>{index > 0 && ' / '}{name}</>
              ))}
              {categoriesNames.length === 0 && <>&nbsp;</>}
            </Stack.Item>
          </Stack>
          <Stack enableScopedSelectors horizontal>
            <Stack enableScopedSelectors vertical verticalAlign='space-between' horizontalAlign="end">
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                {data.IsLocked && <FontIcon iconName="WebComponents" className={iconClass.transactionType} />}
                <FontIcon iconName={transactionTypeToIconMap[data.TransactionType]} className={iconClass.transactionType} />
              </Stack>
              <span className={valueClass}>{data.Value.Value.toFixed(2)} {data.Value.Currency}</span>
            </Stack>
            <Stack enableScopedSelectors vertical verticalAlign='space-between' horizontalAlign="center" className={transactionOptionsClass}>
              <FontIcon iconName="M365InvoicingLogo" className={iconClass.receipt} />
              <IconButton iconProps={{ iconName: isItemSectionHidden ? 'ChevronDown' : 'ChevronUp' }} onClick={toggleIsItemSectionHidden} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {!isItemSectionHidden &&
        <div className={itemsList}>
          <Stack veritical tokens={{ childrenGap: 1 }} style={{ flex: 1, backgroundColor: (data.Items.length < 2 ? null : NeutralColors.gray20) }} verticalAlign={data.Items.length === 0 ? 'center' : 'start'} horizontalAlign="center">
            {data.Items.map((item, index) => (
              <Item key={index} data={item} onChange={(data) => handleItemChange(index, data)} name={item.Name} amount={item.Amount} units={item.Units} value={item.Value} currency={data.Value.Currency} categoriesList={props.categoriesList} deleteItem={() => deleteItem(index)} />
            ))}
            {data.Items.length === 0 && <span style={{ color: NeutralColors.gray50 }}>No items added</span>}
          </Stack>
          <Stack enableScopedSelectors vertical verticalAlign="start" horizontalAlign="center" className={transactionOptionsClass}>
            {isSaveInProgress && <Spinner size={SpinnerSize.small} />}
            {!isSaveInProgress && <IconButton iconProps={{ iconName: 'Save' }} disabled={!isSaveRequired} onClick={saveChanges} />}
            <IconButton iconProps={{ iconName: 'Add' }} onClick={createEmptyItem} />
          </Stack>
        </div >
      }
    </Stack >

  )
}
