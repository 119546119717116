import React, { useState } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { Stack, getTheme, MessageBar, MessageBarType } from '@fluentui/react'

import { NeutralColors } from '@fluentui/theme'

import { TextField } from '@fluentui/react/lib/TextField'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { ThemeProvider } from '@fluentui/react/lib/Theme'
import {
  Navigate
} from 'react-router-dom'

import AuthorizationService from './services/authorization'

export function Login () {
  const defaultTheme = {
    palette: {
      themePrimary: '#0078D4',
      themeDark: '#324c4d'
    }
  }
  const siteWrapperClass = mergeStyles({
    backgroundColor: NeutralColors.gray10,
    height: '100%'
  })

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [success, setSuccess] = useState(false)

  function handleLoginClick (email, password) {
    setDisabled(true)
    setErrorMessage(null)
    AuthorizationService.login(email, password)
      .then((data) => {
        setSuccess(true)
        setDisabled(false)
      })
      .catch(error => {
        setErrorMessage('Cannot connect to the server')
        setDisabled(false)
        console.log(error)
      })
  }

  function handleInputChange (name, value) {
    switch (name) {
      case 'email':
        setEmail(value)
        break
      case 'password':
        setPassword(value)
        break
    }
  }

  const theme = getTheme()

  const loginClass = mergeStyles({
    backgroundColor: '#0078D4',
    height: '100%',
    width: '100%'
  })

  const loginFormClass = mergeStyles({
    backgroundColor: NeutralColors.white,
    width: '400px',
    padding: '20px',
    boxShadow: theme.effects.elevation64
  })

  const logoClass = mergeStyles({
    height: '100px',
    color: NeutralColors.white
  })

  const stackTokens = { childrenGap: 50 }
  const columnProps = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } }
  }

  const emailFieldProps = {
    label: 'Email',
    name: 'email',
    underlined: true,
    onChange: (e) => handleInputChange(e.target.name, e.target.value),
    disabled
  }

  const passwordFieldProps = {
    label: 'Password',
    name: 'password',
    type: 'password',
    canRevealPassword: true,
    revealPasswordAriaLabel: 'Show password',
    underlined: true,
    onChange: (e) => handleInputChange(e.target.name, e.target.value),
    disabled
  }

  const loginButtonProps = {
    text: 'Login',
    allowDisabledFocus: true,
    onClick: () => handleLoginClick(email, password),
    disabled
  }

  const registerButtonProps = {
    text: 'Register',
    allowDisabledFocus: true,
    disabled
  }

  if (success) {
    return <Navigate to="/operations/summary" />
  }

  return (
    <ThemeProvider theme={defaultTheme} className={siteWrapperClass}>
      <link
        rel="stylesheet"
        href="https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/office-ui-fabric-core/11.1.0/css/fabric.min.css"
      />
      <Stack className={loginClass} horizontalAlign="center" verticalAlign='center'>
        <Stack vertical tokens={stackTokens} className={logoClass}><h1>Finapp</h1></Stack>
        <form>
          <Stack vertical horizontalAlign='center' verticalAlign='center' tokens={stackTokens} className={loginFormClass}>
            <Stack {...columnProps}>
              <h2>Access your account</h2>
              {success && <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >Successfully signed in</MessageBar>}
              {errorMessage && <MessageBar
                messageBarType={MessageBarType.blocked}
                isMultiline={false}
              >{errorMessage}</MessageBar>}
              <TextField {...emailFieldProps} />
              <TextField {...passwordFieldProps} />
              <PrimaryButton {...loginButtonProps} />
              <DefaultButton {...registerButtonProps} />
              <h5>Did you forget the password? I&apos;m so sorry!</h5>
            </Stack>
          </Stack>
        </form>
      </Stack >
    </ThemeProvider>

  )
}
