import './index.css'
import ReactDOM from 'react-dom/client'
import React, { useEffect } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { ThemeProvider } from '@fluentui/react/lib/Theme'
import { Stack } from '@fluentui/react'
import { NeutralColors } from '@fluentui/theme'
import { Login } from './login.js'
import { Navbar } from './Navbar.js'
import { CookiesProvider } from 'react-cookie'
import { HashRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom'
import { Operations } from './Operations'
import { Categories } from './categories'
import { useBoolean } from '@fluentui/react-hooks'
import AuthorizationService from './services/authorization'
import StaticService from './services/static'
import { LoaderScreen } from './LoaderScreen'
import { Sidebar } from './Sidebar'
import { EmailAdapters } from './EmailAdapters'

initializeIcons()

export function WorkspaceHeader (props) {
  const location = useLocation().pathname.split('/')

  const headerClass = mergeStyles({
    height: 50
  }, 'ms-Grid-row')

  const firstNameClass = mergeStyles({
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 20
  })
  const secondNameClass = mergeStyles({
    fontSize: '16px',
    marginLeft: 15,
    marginBottom: 12,
    color: NeutralColors.gray120
  })

  return (
    <Stack horizontal className={headerClass}>
      <Stack vertical verticalAlign="end">
        <span className={firstNameClass}>{location[1] && location[1].charAt(0).toUpperCase() + location[1].slice(1)}</span>
      </Stack>
      <Stack vertical verticalAlign="end">
        {location[2] && <span className={secondNameClass}>{location[2].charAt(0).toUpperCase() + location[2].slice(1)}</span>}
      </Stack>
    </Stack>
  )
}

function Layout (props) {
  const [isSidebarMenuVisible, { toggle: toggleIsSidebarMenuVisible }] = useBoolean(true)
  const [isStaticLoaded, { toggle: toggleIsStaticLoaded }] = useBoolean(false)

  const gridClass = mergeStyles({
    height: 'calc(100% - 70px)',
    backgroundColor: NeutralColors.gray10
  }, 'ms-Grid')
  const rowClass = mergeStyles({
    height: '100%'
  }, 'ms-Grid-row')
  const defaultTheme = {
    palette: {
      themePrimary: '#0078D4',
      themeDark: '#324c4d'
    }
  }
  const siteWrapperClass = mergeStyles({
    backgroundColor: NeutralColors.gray10,
    height: '100%'
  })
  const workspaceClass = mergeStyles({
    backgroundColor: NeutralColors.gray10,
    height: '100%'
  }, 'ms-Grid-col', (isSidebarMenuVisible ? 'ms-lg9' : 'ms-lg12'))
  const sidebarClass = mergeStyles({
    backgroundColor: NeutralColors.white,
    height: '100%',
    borderRightWidth: '2px',
    borderRightColor: 'black',
    textAlign: 'right',
    width: '100%',
    display: (isSidebarMenuVisible ? 'block' : 'none')
  }, 'ms-Grid-col', 'ms-lg3')

  useEffect(() => {
    StaticService.loadMccCodes().then(() => {
      toggleIsStaticLoaded()
    })
  }, [])

  if (isStaticLoaded) {
    return (
      <ThemeProvider theme={defaultTheme} className={siteWrapperClass}>
        <Navbar toggleSidebarMenu={toggleIsSidebarMenuVisible} />
        <div className={gridClass} dir="ltr">
          <div className={rowClass}>
            <div className={sidebarClass}><Sidebar /></div>
            <div className={workspaceClass}>
              <div style={{ backgroundColor: NeutralColors.gray10, height: '100%' }} dir="ltr" >
                <WorkspaceHeader />
                <Outlet />
              </div >
            </div>
          </div>
        </div >
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={defaultTheme} className={siteWrapperClass}>
        <LoaderScreen />
      </ThemeProvider>
    )
  }
}

function PrivateRoute () {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return AuthorizationService.getCurrentUser() ? <Outlet /> : <Navigate to="/login" />
}

function App () {
  function handleLoginSuccess (token, ttl) {

  }
  document.body.style.padding = 0
  document.body.style.margin = 0
  document.body.style.fontFamily = 'Segoe UI'
  document.body.style.height = '100%'

  return (
    <CookiesProvider>

      <link
        rel="stylesheet"
        href="https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/office-ui-fabric-core/11.1.0/css/fabric.min.css"
      />
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/operations/summary" replace={true} />}></Route>
            <Route element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route path="/operations" element={<Outlet />}>
                  <Route element={<PrivateRoute />}>
                    <Route path="summary" element={<Operations />} />
                  </Route>
                </Route>
                <Route path="/settings" element={<Outlet />}>
                  <Route element={<PrivateRoute />}>
                    <Route path="categories" element={<Categories />} />
                  </Route>
                </Route>
                <Route path="/adapters" element={<Outlet />}>
                  <Route element={<PrivateRoute />}>
                    <Route path="email" element={<EmailAdapters />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/login" element={<Login onSuccess={(token, ttl) => handleLoginSuccess(token, ttl)} />}></Route>
        </Routes>
      </HashRouter>
    </CookiesProvider>
  )
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
