import React from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'

export function Pill (props) {
  const pillBackground = mergeStyles({
    paddingTop: '0.1em',
    paddingBottom: '0.1em',
    paddingLeft: '0.8em',
    paddingRight: '0.8em',
    borderRadius: '1em',
    cursor: 'pointer'
  }, props.style)

  return (
    <span className={pillBackground} onClick={props.onClick}>{props.text}</span>
  )
}
