const codes = [
  {
    id: 742,
    description: 'Veterinary Services'
  },
  {
    id: 763,
    description: 'Agricultural Co-Operatives'
  },
  {
    id: 780,
    description: 'Horticultural Services, Landscaping Services'
  },
  {
    id: 1520,
    description: 'General Contractors-Residential And Commercial'
  },
  {
    id: 1711,
    description: 'Air Conditioning Contractors \u2013 Sales And Installation, Heating Contractors \u2013 Sales, Service, Installation'
  },
  {
    id: 1731,
    description: 'Electrical Contractors'
  },
  {
    id: 1740,
    description: 'Insulation \u2013 Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework And Masonry Contractors, Tile Settings Contractors'
  },
  {
    id: 1750,
    description: 'Carpentry Contractors'
  },
  {
    id: 1761,
    description: 'Roofing \u2013 Contractors, Sheet Metal Work \u2013 Contractors, Siding \u2013 Contractors'
  },
  {
    id: 1771,
    description: 'Contractors \u2013 Concrete Work'
  },
  {
    id: 1799,
    description: 'Contractors \u2013 Special Trade, Not Elsewhere Classified'
  },
  {
    id: 2741,
    description: 'Miscellaneous Publishing And Printing'
  },
  {
    id: 2791,
    description: 'Typesetting, Plate Making, & Related Services'
  },
  {
    id: 2842,
    description: 'Specialty Cleaning, Polishing, And Sanitation Preparations'
  },
  {
    id: 3000,
    description: 'United Airlines'
  },
  {
    id: 3001,
    description: 'American Airlines'
  },
  {
    id: 3002,
    description: 'Pan American'
  },
  {
    id: 3003,
    description: 'Airlines'
  },
  {
    id: 3004,
    description: 'Trans World Airlines'
  },
  {
    id: 3005,
    description: 'British Airways'
  },
  {
    id: 3006,
    description: 'Japan Airlines'
  },
  {
    id: 3007,
    description: 'Air France'
  },
  {
    id: 3008,
    description: 'Lufthansa'
  },
  {
    id: 3009,
    description: 'Air Canada'
  },
  {
    id: 3010,
    description: 'Klm (Royal Dutch Airlines)'
  },
  {
    id: 3011,
    description: 'Aeorflot'
  },
  {
    id: 3012,
    description: 'Quantas'
  },
  {
    id: 3013,
    description: 'Alitalia'
  },
  {
    id: 3014,
    description: 'Saudia Arabian Airlines'
  },
  {
    id: 3015,
    description: 'Swissair'
  },
  {
    id: 3016,
    description: 'Sas'
  },
  {
    id: 3017,
    description: 'South African Airways'
  },
  {
    id: 3018,
    description: 'Varig (Brazil)'
  },
  {
    id: 3019,
    description: 'Airlines'
  },
  {
    id: 3020,
    description: 'Air-India'
  },
  {
    id: 3021,
    description: 'Air Algerie'
  },
  {
    id: 3022,
    description: 'Philippine Airlines'
  },
  {
    id: 3023,
    description: 'Mexicana'
  },
  {
    id: 3024,
    description: 'Pakistan International'
  },
  {
    id: 3025,
    description: 'Air New Zealand'
  },
  {
    id: 3026,
    description: 'Airlines'
  },
  {
    id: 3027,
    description: 'Uta/interair'
  },
  {
    id: 3028,
    description: 'Air Malta'
  },
  {
    id: 3029,
    description: 'Sabena'
  },
  {
    id: 3030,
    description: 'Aerolineas Argentinas'
  },
  {
    id: 3031,
    description: 'Olympic Airways'
  },
  {
    id: 3032,
    description: 'El Al'
  },
  {
    id: 3033,
    description: 'Ansett Airlines'
  },
  {
    id: 3034,
    description: 'Austrainlian Airlines'
  },
  {
    id: 3035,
    description: 'Tap (Portugal)'
  },
  {
    id: 3036,
    description: 'Vasp (Brazil)'
  },
  {
    id: 3037,
    description: 'Egyptair'
  },
  {
    id: 3038,
    description: 'Kuwait Airlines'
  },
  {
    id: 3039,
    description: 'Avianca'
  },
  {
    id: 3040,
    description: 'Gulf Air (Bahrain)'
  },
  {
    id: 3041,
    description: 'Balkan-Bulgarian Airlines'
  },
  {
    id: 3042,
    description: 'Finnair'
  },
  {
    id: 3043,
    description: 'Aer Lingus'
  },
  {
    id: 3044,
    description: 'Air Lanka'
  },
  {
    id: 3045,
    description: 'Nigeria Airways'
  },
  {
    id: 3046,
    description: 'Cruzeiro Do Sul (Brazij)'
  },
  {
    id: 3047,
    description: 'Thy (Turkey)'
  },
  {
    id: 3048,
    description: 'Royal Air Maroc'
  },
  {
    id: 3049,
    description: 'Tunis Air'
  },
  {
    id: 3050,
    description: 'Icelandair'
  },
  {
    id: 3051,
    description: 'Austrian Airlines'
  },
  {
    id: 3052,
    description: 'Lanchile'
  },
  {
    id: 3053,
    description: 'Aviaco (Spain)'
  },
  {
    id: 3054,
    description: 'Ladeco (Chile)'
  },
  {
    id: 3055,
    description: 'Lab (Bolivia)'
  },
  {
    id: 3056,
    description: 'Quebecaire'
  },
  {
    id: 3057,
    description: 'Eastwest Airlines (Australia)'
  },
  {
    id: 3058,
    description: 'Delta'
  },
  {
    id: 3059,
    description: 'Airlines'
  },
  {
    id: 3060,
    description: 'Northwest'
  },
  {
    id: 3061,
    description: 'Continental'
  },
  {
    id: 3062,
    description: 'Western'
  },
  {
    id: 3063,
    description: 'Us Air'
  },
  {
    id: 3064,
    description: 'Airlines'
  },
  {
    id: 3065,
    description: 'Airinter'
  },
  {
    id: 3066,
    description: 'Southwest'
  },
  {
    id: 3067,
    description: 'Airlines'
  },
  {
    id: 3068,
    description: 'Airlines'
  },
  {
    id: 3069,
    description: 'Airlines'
  },
  {
    id: 3070,
    description: 'Airlines'
  },
  {
    id: 3071,
    description: 'Air British Colubia'
  },
  {
    id: 3072,
    description: 'Airlines'
  },
  {
    id: 3073,
    description: 'Airlines'
  },
  {
    id: 3074,
    description: 'Airlines'
  },
  {
    id: 3075,
    description: 'Singapore Airlines'
  },
  {
    id: 3076,
    description: 'Aeromexico'
  },
  {
    id: 3077,
    description: 'Thai Airways'
  },
  {
    id: 3078,
    description: 'China Airlines'
  },
  {
    id: 3079,
    description: 'Airlines'
  },
  {
    id: 3080,
    description: 'Airlines'
  },
  {
    id: 3081,
    description: 'Nordair'
  },
  {
    id: 3082,
    description: 'Korean Airlines'
  },
  {
    id: 3083,
    description: 'Air Afrigue'
  },
  {
    id: 3084,
    description: 'Eva Airlines'
  },
  {
    id: 3085,
    description: 'Midwest Express Airlines, Inc.'
  },
  {
    id: 3086,
    description: 'Airlines'
  },
  {
    id: 3087,
    description: 'Metro Airlines'
  },
  {
    id: 3088,
    description: 'Croatia Airlines'
  },
  {
    id: 3089,
    description: 'Transaero'
  },
  {
    id: 3090,
    description: 'Airlines'
  },
  {
    id: 3091,
    description: 'Airlines'
  },
  {
    id: 3092,
    description: 'Airlines'
  },
  {
    id: 3093,
    description: 'Airlines'
  },
  {
    id: 3094,
    description: 'Zambia Airways'
  },
  {
    id: 3095,
    description: 'Airlines'
  },
  {
    id: 3096,
    description: 'Air Zimbabwe'
  },
  {
    id: 3097,
    description: 'Airlines'
  },
  {
    id: 3098,
    description: 'Airlines'
  },
  {
    id: 3099,
    description: 'Cathay Pacific'
  },
  {
    id: 3100,
    description: 'Malaysian Airline System'
  },
  {
    id: 3101,
    description: 'Airlines'
  },
  {
    id: 3102,
    description: 'Iberia'
  },
  {
    id: 3103,
    description: 'Garuda (Indonesia)'
  },
  {
    id: 3104,
    description: 'Airlines'
  },
  {
    id: 3105,
    description: 'Airlines'
  },
  {
    id: 3106,
    description: 'Braathens S.a.f.e. (Norway)'
  },
  {
    id: 3107,
    description: 'Airlines'
  },
  {
    id: 3108,
    description: 'Airlines'
  },
  {
    id: 3109,
    description: 'Airlines'
  },
  {
    id: 3110,
    description: 'Wings Airways'
  },
  {
    id: 3111,
    description: 'British Midland'
  },
  {
    id: 3112,
    description: 'Windward Island'
  },
  {
    id: 3113,
    description: 'Airlines'
  },
  {
    id: 3114,
    description: 'Airlines'
  },
  {
    id: 3115,
    description: 'Airlines'
  },
  {
    id: 3116,
    description: 'Airlines'
  },
  {
    id: 3117,
    description: 'Viasa'
  },
  {
    id: 3118,
    description: 'Valley Airlines'
  },
  {
    id: 3119,
    description: 'Airlines'
  },
  {
    id: 3120,
    description: 'Airlines'
  },
  {
    id: 3121,
    description: 'Airlines'
  },
  {
    id: 3122,
    description: 'Airlines'
  },
  {
    id: 3123,
    description: 'Airlines'
  },
  {
    id: 3124,
    description: 'Airlines'
  },
  {
    id: 3125,
    description: 'Tan'
  },
  {
    id: 3126,
    description: 'Talair'
  },
  {
    id: 3127,
    description: 'Taca International'
  },
  {
    id: 3128,
    description: 'Airlines'
  },
  {
    id: 3129,
    description: 'Surinam Airways'
  },
  {
    id: 3130,
    description: 'Sun World International'
  },
  {
    id: 3131,
    description: 'Airlines'
  },
  {
    id: 3132,
    description: 'Airlines'
  },
  {
    id: 3133,
    description: 'Sunbelt Airlines'
  },
  {
    id: 3134,
    description: 'Airlines'
  },
  {
    id: 3135,
    description: 'Sudan Airways'
  },
  {
    id: 3136,
    description: 'Airlines'
  },
  {
    id: 3137,
    description: 'Singleton'
  },
  {
    id: 3138,
    description: 'Simmons Airlines'
  },
  {
    id: 3139,
    description: 'Airlines'
  },
  {
    id: 3140,
    description: 'Airlines'
  },
  {
    id: 3141,
    description: 'Airlines'
  },
  {
    id: 3142,
    description: 'Airlines'
  },
  {
    id: 3143,
    description: 'Scenic Airlines'
  },
  {
    id: 3144,
    description: 'Virgin Atlantic'
  },
  {
    id: 3145,
    description: 'San Juan Airlines'
  },
  {
    id: 3146,
    description: 'Luxair'
  },
  {
    id: 3147,
    description: 'Airlines'
  },
  {
    id: 3148,
    description: 'Airlines'
  },
  {
    id: 3149,
    description: 'Airlines'
  },
  {
    id: 3150,
    description: 'Airlines'
  },
  {
    id: 3151,
    description: 'Air Zaire'
  },
  {
    id: 3152,
    description: 'Airlines'
  },
  {
    id: 3153,
    description: 'Airlines'
  },
  {
    id: 3154,
    description: 'Princeville'
  },
  {
    id: 3155,
    description: 'Airlines'
  },
  {
    id: 3156,
    description: 'Airlines'
  },
  {
    id: 3157,
    description: 'Airlines'
  },
  {
    id: 3158,
    description: 'Airlines'
  },
  {
    id: 3159,
    description: 'Pba'
  },
  {
    id: 3160,
    description: 'Airlines'
  },
  {
    id: 3161,
    description: 'All Nippon Airways'
  },
  {
    id: 3162,
    description: 'Airlines'
  },
  {
    id: 3163,
    description: 'Airlines'
  },
  {
    id: 3164,
    description: 'Norontair'
  },
  {
    id: 3165,
    description: 'New York Helicopter'
  },
  {
    id: 3166,
    description: 'Airlines'
  },
  {
    id: 3167,
    description: 'Airlines'
  },
  {
    id: 3168,
    description: 'Airlines'
  },
  {
    id: 3169,
    description: 'Airlines'
  },
  {
    id: 3170,
    description: 'Nount Cook'
  },
  {
    id: 3171,
    description: 'Canadian Airlines International'
  },
  {
    id: 3172,
    description: 'Nationair'
  },
  {
    id: 3173,
    description: 'Airlines'
  },
  {
    id: 3174,
    description: 'Airlines'
  },
  {
    id: 3175,
    description: 'Airlines'
  },
  {
    id: 3176,
    description: 'Metroflight Airlines'
  },
  {
    id: 3177,
    description: 'Airlines'
  },
  {
    id: 3178,
    description: 'Mesa Air'
  },
  {
    id: 3179,
    description: 'Airlines'
  },
  {
    id: 3180,
    description: 'Airlines'
  },
  {
    id: 3181,
    description: 'Malev'
  },
  {
    id: 3182,
    description: 'Lot (Poland)'
  },
  {
    id: 3183,
    description: 'Airlines'
  },
  {
    id: 3184,
    description: 'Liat'
  },
  {
    id: 3185,
    description: 'Lav (Venezuela)'
  },
  {
    id: 3186,
    description: 'Lap (Paraguay)'
  },
  {
    id: 3187,
    description: 'Lacsa (Costa Rica)'
  },
  {
    id: 3188,
    description: 'Airlines'
  },
  {
    id: 3189,
    description: 'Airlines'
  },
  {
    id: 3190,
    description: 'Jugoslav Air'
  },
  {
    id: 3191,
    description: 'Island Airlines'
  },
  {
    id: 3192,
    description: 'Iran Air'
  },
  {
    id: 3193,
    description: 'Indian Airlines'
  },
  {
    id: 3194,
    description: 'Airlines'
  },
  {
    id: 3195,
    description: 'Airlines'
  },
  {
    id: 3196,
    description: 'Hawaiian Air'
  },
  {
    id: 3197,
    description: 'Havasu Airlines'
  },
  {
    id: 3198,
    description: 'Airlines'
  },
  {
    id: 3199,
    description: 'Airlines'
  },
  {
    id: 3200,
    description: 'Fuyana Airways'
  },
  {
    id: 3201,
    description: 'Airlines'
  },
  {
    id: 3202,
    description: 'Airlines'
  },
  {
    id: 3203,
    description: 'Golden Pacific Air'
  },
  {
    id: 3204,
    description: 'Freedom Air'
  },
  {
    id: 3205,
    description: 'Airlines'
  },
  {
    id: 3206,
    description: 'Airlines'
  },
  {
    id: 3207,
    description: 'Airlines'
  },
  {
    id: 3208,
    description: 'Airlines'
  },
  {
    id: 3209,
    description: 'Airlines'
  },
  {
    id: 3210,
    description: 'Airlines'
  },
  {
    id: 3211,
    description: 'Airlines'
  },
  {
    id: 3212,
    description: 'Dominicana'
  },
  {
    id: 3213,
    description: 'Airlines'
  },
  {
    id: 3214,
    description: 'Airlines'
  },
  {
    id: 3215,
    description: 'Dan Air Services'
  },
  {
    id: 3216,
    description: 'Cumberland Airlines'
  },
  {
    id: 3217,
    description: 'Csa'
  },
  {
    id: 3218,
    description: 'Crown Air'
  },
  {
    id: 3219,
    description: 'Copa'
  },
  {
    id: 3220,
    description: 'Compania Faucett'
  },
  {
    id: 3221,
    description: 'Transportes Aeros Militares Eccuatoranos'
  },
  {
    id: 3222,
    description: 'Command Airways'
  },
  {
    id: 3223,
    description: 'Comair'
  },
  {
    id: 3224,
    description: 'Airlines'
  },
  {
    id: 3225,
    description: 'Airlines'
  },
  {
    id: 3226,
    description: 'Airlines'
  },
  {
    id: 3227,
    description: 'Airlines'
  },
  {
    id: 3228,
    description: 'Cayman Airways'
  },
  {
    id: 3229,
    description: 'Saeta Sociaedad Ecuatorianos De Transportes Aereos'
  },
  {
    id: 3230,
    description: 'Airlines'
  },
  {
    id: 3231,
    description: 'Sasha Servicio Aero De Honduras'
  },
  {
    id: 3232,
    description: 'Airlines'
  },
  {
    id: 3233,
    description: 'Capitol Air'
  },
  {
    id: 3234,
    description: 'Bwia'
  },
  {
    id: 3235,
    description: 'Brokway Air'
  },
  {
    id: 3236,
    description: 'Airlines'
  },
  {
    id: 3237,
    description: 'Airlines'
  },
  {
    id: 3238,
    description: 'Bemidji Airlines'
  },
  {
    id: 3239,
    description: 'Bar Harbor Airlines'
  },
  {
    id: 3240,
    description: 'Bahamasair'
  },
  {
    id: 3241,
    description: 'Aviateca (Guatemala)'
  },
  {
    id: 3242,
    description: 'Avensa'
  },
  {
    id: 3243,
    description: 'Austrian Air Service'
  },
  {
    id: 3244,
    description: 'Airlines'
  },
  {
    id: 3245,
    description: 'Airlines'
  },
  {
    id: 3246,
    description: 'Airlines'
  },
  {
    id: 3247,
    description: 'Airlines'
  },
  {
    id: 3248,
    description: 'Airlines'
  },
  {
    id: 3249,
    description: 'Airlines'
  },
  {
    id: 3250,
    description: 'Airlines'
  },
  {
    id: 3251,
    description: 'Aloha Airlines'
  },
  {
    id: 3252,
    description: 'Alm'
  },
  {
    id: 3253,
    description: 'America West'
  },
  {
    id: 3254,
    description: 'Trump Airline'
  },
  {
    id: 3255,
    description: 'Airlines'
  },
  {
    id: 3256,
    description: 'Alaska Airlines'
  },
  {
    id: 3257,
    description: 'Airlines'
  },
  {
    id: 3258,
    description: 'Airlines'
  },
  {
    id: 3259,
    description: 'American Trans Air'
  },
  {
    id: 3260,
    description: 'Airlines'
  },
  {
    id: 3261,
    description: 'Air China'
  },
  {
    id: 3262,
    description: 'Reno Air, Inc.'
  },
  {
    id: 3263,
    description: 'Airlines'
  },
  {
    id: 3264,
    description: 'Airlines'
  },
  {
    id: 3265,
    description: 'Airlines'
  },
  {
    id: 3266,
    description: 'Air Seychelles'
  },
  {
    id: 3267,
    description: 'Air Panama'
  },
  {
    id: 3268,
    description: 'Airlines'
  },
  {
    id: 3269,
    description: 'Airlines'
  },
  {
    id: 3270,
    description: 'Airlines'
  },
  {
    id: 3271,
    description: 'Airlines'
  },
  {
    id: 3272,
    description: 'Airlines'
  },
  {
    id: 3273,
    description: 'Airlines'
  },
  {
    id: 3274,
    description: 'Airlines'
  },
  {
    id: 3275,
    description: 'Airlines'
  },
  {
    id: 3276,
    description: 'Airlines'
  },
  {
    id: 3277,
    description: 'Airlines'
  },
  {
    id: 3278,
    description: 'Airlines'
  },
  {
    id: 3279,
    description: 'Airlines'
  },
  {
    id: 3280,
    description: 'Air Jamaica'
  },
  {
    id: 3281,
    description: 'Airlines'
  },
  {
    id: 3282,
    description: 'Air Djibouti'
  },
  {
    id: 3283,
    description: 'Airlines'
  },
  {
    id: 3284,
    description: 'Aero Virgin Islands'
  },
  {
    id: 3285,
    description: 'Aero Peru'
  },
  {
    id: 3286,
    description: 'Aerolineas Nicaraguensis'
  },
  {
    id: 3287,
    description: 'Aero Coach Avaiation'
  },
  {
    id: 3288,
    description: 'Airlines'
  },
  {
    id: 3289,
    description: 'Airlines'
  },
  {
    id: 3290,
    description: 'Airlines'
  },
  {
    id: 3291,
    description: 'Ariana Afghan'
  },
  {
    id: 3292,
    description: 'Cyprus Airways'
  },
  {
    id: 3293,
    description: 'Ecuatoriana'
  },
  {
    id: 3294,
    description: 'Ethiopian Airlines'
  },
  {
    id: 3295,
    description: 'Kenya Airlines'
  },
  {
    id: 3296,
    description: 'Airlines'
  },
  {
    id: 3297,
    description: 'Airlines'
  },
  {
    id: 3298,
    description: 'Air Mauritius'
  },
  {
    id: 3299,
    description: 'Widero\u2019S Flyveselskap'
  },
  {
    id: 3300,
    description: 'Azul Air'
  },
  {
    id: 3351,
    description: 'Affiliated Auto Rental'
  },
  {
    id: 3352,
    description: 'American Intl Rent-A-Car'
  },
  {
    id: 3353,
    description: 'Brooks Rent-A-Car'
  },
  {
    id: 3354,
    description: 'Action Auto Rental'
  },
  {
    id: 3355,
    description: 'Car Rental'
  },
  {
    id: 3356,
    description: 'Car Rental'
  },
  {
    id: 3357,
    description: 'Hertz Rent-A-Car'
  },
  {
    id: 3358,
    description: 'Car Rental'
  },
  {
    id: 3359,
    description: 'Payless Car Rental'
  },
  {
    id: 3360,
    description: 'Snappy Car Rental'
  },
  {
    id: 3361,
    description: 'Airways Rent-A-Car'
  },
  {
    id: 3362,
    description: 'Altra Auto Rental'
  },
  {
    id: 3363,
    description: 'Car Rental'
  },
  {
    id: 3364,
    description: 'Agency Rent-A-Car'
  },
  {
    id: 3365,
    description: 'Car Rental'
  },
  {
    id: 3366,
    description: 'Budget Rent-A-Car'
  },
  {
    id: 3367,
    description: 'Car Rental'
  },
  {
    id: 3368,
    description: 'Holiday Rent-A-Wreck'
  },
  {
    id: 3369,
    description: 'Car Rental'
  },
  {
    id: 3370,
    description: 'Rent-A-Wreck'
  },
  {
    id: 3371,
    description: 'Car Rental'
  },
  {
    id: 3372,
    description: 'Car Rental'
  },
  {
    id: 3373,
    description: 'Car Rental'
  },
  {
    id: 3374,
    description: 'Car Rental'
  },
  {
    id: 3375,
    description: 'Car Rental'
  },
  {
    id: 3376,
    description: 'Ajax Rent-A-Car'
  },
  {
    id: 3377,
    description: 'Car Rental'
  },
  {
    id: 3378,
    description: 'Car Rental'
  },
  {
    id: 3379,
    description: 'Car Rental'
  },
  {
    id: 3380,
    description: 'Car Rental'
  },
  {
    id: 3381,
    description: 'Europ Car'
  },
  {
    id: 3382,
    description: 'Car Rental'
  },
  {
    id: 3383,
    description: 'Car Rental'
  },
  {
    id: 3384,
    description: 'Car Rental'
  },
  {
    id: 3385,
    description: 'Tropical Rent-A-Car'
  },
  {
    id: 3386,
    description: 'Showcase Rental Cars'
  },
  {
    id: 3387,
    description: 'Alamo Rent-A-Car'
  },
  {
    id: 3388,
    description: 'Car Rental'
  },
  {
    id: 3389,
    description: 'Avis Rent-A-Car'
  },
  {
    id: 3390,
    description: 'Dollar Rent-A-Car'
  },
  {
    id: 3391,
    description: 'Europe By Car'
  },
  {
    id: 3392,
    description: 'Car Rental'
  },
  {
    id: 3393,
    description: 'National Car Rental'
  },
  {
    id: 3394,
    description: 'Kemwell Group Rent-A-Car'
  },
  {
    id: 3395,
    description: 'Thrifty Rent-A-Car'
  },
  {
    id: 3396,
    description: 'Tilden Tent-A-Car'
  },
  {
    id: 3397,
    description: 'Car Rental'
  },
  {
    id: 3398,
    description: 'Econo-Car Rent-A-Car'
  },
  {
    id: 3399,
    description: 'Car Rental'
  },
  {
    id: 3400,
    description: 'Auto Host Cost Car Rentals'
  },
  {
    id: 3401,
    description: 'Car Rental'
  },
  {
    id: 3402,
    description: 'Car Rental'
  },
  {
    id: 3403,
    description: 'Car Rental'
  },
  {
    id: 3404,
    description: 'Car Rental'
  },
  {
    id: 3405,
    description: 'Enterprise Rent-A-Car'
  },
  {
    id: 3406,
    description: 'Car Rental'
  },
  {
    id: 3407,
    description: 'Car Rental'
  },
  {
    id: 3408,
    description: 'Car Rental'
  },
  {
    id: 3409,
    description: 'General Rent-A-Car'
  },
  {
    id: 3410,
    description: 'Car Rental'
  },
  {
    id: 3411,
    description: 'Car Rental'
  },
  {
    id: 3412,
    description: 'A-1 Rent-A-Car'
  },
  {
    id: 3413,
    description: 'Car Rental'
  },
  {
    id: 3414,
    description: 'Godfrey Natl Rent-A-Car'
  },
  {
    id: 3415,
    description: 'Car Rental'
  },
  {
    id: 3416,
    description: 'Car Rental'
  },
  {
    id: 3417,
    description: 'Car Rental'
  },
  {
    id: 3418,
    description: 'Car Rental'
  },
  {
    id: 3419,
    description: 'Alpha Rent-A-Car'
  },
  {
    id: 3420,
    description: 'Ansa Intl Rent-A-Car'
  },
  {
    id: 3421,
    description: 'Allstae Rent-A-Car'
  },
  {
    id: 3422,
    description: 'Car Rental'
  },
  {
    id: 3423,
    description: 'Avcar Rent-A-Car'
  },
  {
    id: 3424,
    description: 'Car Rental'
  },
  {
    id: 3425,
    description: 'Automate Rent-A-Car'
  },
  {
    id: 3426,
    description: 'Car Rental'
  },
  {
    id: 3427,
    description: 'Avon Rent-A-Car'
  },
  {
    id: 3428,
    description: 'Carey Rent-A-Car'
  },
  {
    id: 3429,
    description: 'Insurance Rent-A-Car'
  },
  {
    id: 3430,
    description: 'Major Rent-A-Car'
  },
  {
    id: 3431,
    description: 'Replacement Rent-A-Car'
  },
  {
    id: 3432,
    description: 'Reserve Rent-A-Car'
  },
  {
    id: 3433,
    description: 'Ugly Duckling Rent-A-Car'
  },
  {
    id: 3434,
    description: 'Usa Rent-A-Car'
  },
  {
    id: 3435,
    description: 'Value Rent-A-Car'
  },
  {
    id: 3436,
    description: 'Autohansa Rent-A-Car'
  },
  {
    id: 3437,
    description: 'Cite Rent-A-Car'
  },
  {
    id: 3438,
    description: 'Interent Rent-A-Car'
  },
  {
    id: 3439,
    description: 'Milleville Rent-A-Car'
  },
  {
    id: 3440,
    description: 'Via Route Rent-A-Car'
  },
  {
    id: 3441,
    description: 'Car Rental'
  },
  {
    id: 3501,
    description: 'Holiday Inns, Holiday Inn Express'
  },
  {
    id: 3502,
    description: 'Best Western Hotels'
  },
  {
    id: 3503,
    description: 'Sheraton Hotels'
  },
  {
    id: 3504,
    description: 'Hilton Hotels'
  },
  {
    id: 3505,
    description: 'Forte Hotels'
  },
  {
    id: 3506,
    description: 'Golden Tulip Hotels'
  },
  {
    id: 3507,
    description: 'Friendship Inns'
  },
  {
    id: 3508,
    description: 'Quality Inns, Quality Suites'
  },
  {
    id: 3509,
    description: 'Marriott Hotels'
  },
  {
    id: 3510,
    description: 'Days Inn, Daystop'
  },
  {
    id: 3511,
    description: 'Arabella Hotels'
  },
  {
    id: 3512,
    description: 'Inter-Continental Hotels'
  },
  {
    id: 3513,
    description: 'Westin Hotels'
  },
  {
    id: 3514,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3515,
    description: 'Rodeway Inns'
  },
  {
    id: 3516,
    description: 'La Quinta Motor Inns'
  },
  {
    id: 3517,
    description: 'Americana Hotels'
  },
  {
    id: 3518,
    description: 'Sol Hotels'
  },
  {
    id: 3519,
    description: 'Pullman International Hotels'
  },
  {
    id: 3520,
    description: 'Meridien Hotels'
  },
  {
    id: 3521,
    description: 'Crest Hotels (See Forte Hotels)'
  },
  {
    id: 3522,
    description: 'Tokyo Hotel'
  },
  {
    id: 3523,
    description: 'Pennsula Hotel'
  },
  {
    id: 3524,
    description: 'Welcomgroup Hotels'
  },
  {
    id: 3525,
    description: 'Dunfey Hotels'
  },
  {
    id: 3526,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3527,
    description: 'Downtowner-Passport Hotel'
  },
  {
    id: 3528,
    description: 'Red Lion Hotels, Red Lion Inns'
  },
  {
    id: 3529,
    description: 'Cp Hotels'
  },
  {
    id: 3530,
    description: 'Renaissance Hotels, Stouffer Hotels'
  },
  {
    id: 3531,
    description: 'Astir Hotels'
  },
  {
    id: 3532,
    description: 'Sun Route Hotels'
  },
  {
    id: 3533,
    description: 'Hotel Ibis'
  },
  {
    id: 3534,
    description: 'Southern Pacific Hotels'
  },
  {
    id: 3535,
    description: 'Hilton International'
  },
  {
    id: 3536,
    description: 'Amfac Hotels'
  },
  {
    id: 3537,
    description: 'Ana Hotel'
  },
  {
    id: 3538,
    description: 'Concorde Hotels'
  },
  {
    id: 3539,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3540,
    description: 'Iberotel Hotels'
  },
  {
    id: 3541,
    description: 'Hotel Okura'
  },
  {
    id: 3542,
    description: 'Royal Hotels'
  },
  {
    id: 3543,
    description: 'Four Seasons Hotels'
  },
  {
    id: 3544,
    description: 'Ciga Hotels'
  },
  {
    id: 3545,
    description: 'Shangri-La International'
  },
  {
    id: 3546,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3547,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3548,
    description: 'Hoteles Melia'
  },
  {
    id: 3549,
    description: 'Auberge Des Governeurs'
  },
  {
    id: 3550,
    description: 'Regal 8 Inns'
  },
  {
    id: 3551,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3552,
    description: 'Coast Hotels'
  },
  {
    id: 3553,
    description: 'Park Inns International'
  },
  {
    id: 3554,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3555,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3556,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3557,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3558,
    description: 'Jolly Hotels'
  },
  {
    id: 3559,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3560,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3561,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3562,
    description: 'Comfort Inns'
  },
  {
    id: 3563,
    description: 'Journey\u2019S End Motls'
  },
  {
    id: 3564,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3565,
    description: 'Relax Inns'
  },
  {
    id: 3566,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3567,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3568,
    description: 'Ladbroke Hotels'
  },
  {
    id: 3569,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3570,
    description: 'Forum Hotels'
  },
  {
    id: 3571,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3572,
    description: 'Miyako Hotels'
  },
  {
    id: 3573,
    description: 'Sandman Hotels'
  },
  {
    id: 3574,
    description: 'Venture Inns'
  },
  {
    id: 3575,
    description: 'Vagabond Hotels'
  },
  {
    id: 3576,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3577,
    description: 'Mandarin Oriental Hotel'
  },
  {
    id: 3578,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3579,
    description: 'Hotel Mercure'
  },
  {
    id: 3580,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3581,
    description: 'Delta Hotel'
  },
  {
    id: 3582,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3583,
    description: 'Sas Hotels'
  },
  {
    id: 3584,
    description: 'Princess Hotels International'
  },
  {
    id: 3585,
    description: 'Hungar Hotels'
  },
  {
    id: 3586,
    description: 'Sokos Hotels'
  },
  {
    id: 3587,
    description: 'Doral Hotels'
  },
  {
    id: 3588,
    description: 'Helmsley Hotels'
  },
  {
    id: 3589,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3590,
    description: 'Fairmont Hotels'
  },
  {
    id: 3591,
    description: 'Sonesta Hotels'
  },
  {
    id: 3592,
    description: 'Omni Hotels'
  },
  {
    id: 3593,
    description: 'Cunard Hotels'
  },
  {
    id: 3594,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3595,
    description: 'Hospitality International'
  },
  {
    id: 3596,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3597,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3598,
    description: 'Regent International Hotels'
  },
  {
    id: 3599,
    description: 'Pannonia Hotels'
  },
  {
    id: 3600,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3601,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3602,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3603,
    description: 'Noah\u2019S Hotels'
  },
  {
    id: 3604,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3605,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3606,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3607,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3608,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3609,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3610,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3611,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3612,
    description: 'Movenpick Hotels'
  },
  {
    id: 3613,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3614,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3615,
    description: 'Travelodge'
  },
  {
    id: 3616,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3617,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3618,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3619,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3620,
    description: 'Telford International'
  },
  {
    id: 3621,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3622,
    description: 'Merlin Hotels'
  },
  {
    id: 3623,
    description: 'Dorint Hotels'
  },
  {
    id: 3624,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3625,
    description: 'Hotle Universale'
  },
  {
    id: 3626,
    description: 'Prince Hotels'
  },
  {
    id: 3627,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3628,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3629,
    description: 'Dan Hotels'
  },
  {
    id: 3630,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3631,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3632,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3633,
    description: 'Rank Hotels'
  },
  {
    id: 3634,
    description: 'Swissotel'
  },
  {
    id: 3635,
    description: 'Reso Hotels'
  },
  {
    id: 3636,
    description: 'Sarova Hotels'
  },
  {
    id: 3637,
    description: 'Ramada Inns, Ramada Limited'
  },
  {
    id: 3638,
    description: 'Ho Jo Inn, Howard Johnson'
  },
  {
    id: 3639,
    description: 'Mount Charlotte Thistle'
  },
  {
    id: 3640,
    description: 'Hyatt Hotel'
  },
  {
    id: 3641,
    description: 'Sofitel Hotels'
  },
  {
    id: 3642,
    description: 'Novotel Hotels'
  },
  {
    id: 3643,
    description: 'Steigenberger Hotels'
  },
  {
    id: 3644,
    description: 'Econo Lodges'
  },
  {
    id: 3645,
    description: 'Queens Moat Houses'
  },
  {
    id: 3646,
    description: 'Swallow Hotels'
  },
  {
    id: 3647,
    description: 'Husa Hotels'
  },
  {
    id: 3648,
    description: 'De Vere Hotels'
  },
  {
    id: 3649,
    description: 'Radisson Hotels'
  },
  {
    id: 3650,
    description: 'Red Rook Inns'
  },
  {
    id: 3651,
    description: 'Imperial London Hotel'
  },
  {
    id: 3652,
    description: 'Embassy Hotels'
  },
  {
    id: 3653,
    description: 'Penta Hotels'
  },
  {
    id: 3654,
    description: 'Loews Hotels'
  },
  {
    id: 3655,
    description: 'Scandic Hotels'
  },
  {
    id: 3656,
    description: 'Sara Hotels'
  },
  {
    id: 3657,
    description: 'Oberoi Hotels'
  },
  {
    id: 3658,
    description: 'Otani Hotels'
  },
  {
    id: 3659,
    description: 'Taj Hotels International'
  },
  {
    id: 3660,
    description: 'Knights Inns'
  },
  {
    id: 3661,
    description: 'Metropole Hotels'
  },
  {
    id: 3662,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3663,
    description: 'Hoteles El Presidents'
  },
  {
    id: 3664,
    description: 'Flag Inn'
  },
  {
    id: 3665,
    description: 'Hampton Inns'
  },
  {
    id: 3666,
    description: 'Stakis Hotels'
  },
  {
    id: 3667,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3668,
    description: 'Maritim Hotels'
  },
  {
    id: 3669,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3670,
    description: 'Arcard Hotels'
  },
  {
    id: 3671,
    description: 'Arctia Hotels'
  },
  {
    id: 3672,
    description: 'Campaniel Hotels'
  },
  {
    id: 3673,
    description: 'Ibusz Hotels'
  },
  {
    id: 3674,
    description: 'Rantasipi Hotels'
  },
  {
    id: 3675,
    description: 'Interhotel Cedok'
  },
  {
    id: 3676,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3677,
    description: 'Climat De France Hotels'
  },
  {
    id: 3678,
    description: 'Cumulus Hotels'
  },
  {
    id: 3679,
    description: 'Danubius Hotel'
  },
  {
    id: 3680,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3681,
    description: 'Adams Mark Hotels'
  },
  {
    id: 3682,
    description: 'Allstar Inns'
  },
  {
    id: 3683,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3684,
    description: 'Budget Host Inns'
  },
  {
    id: 3685,
    description: 'Budgetel Hotels'
  },
  {
    id: 3686,
    description: 'Suisse Chalets'
  },
  {
    id: 3687,
    description: 'Clarion Hotels'
  },
  {
    id: 3688,
    description: 'Compri Hotels'
  },
  {
    id: 3689,
    description: 'Consort Hotels'
  },
  {
    id: 3690,
    description: 'Courtyard By Marriott'
  },
  {
    id: 3691,
    description: 'Dillion Inns'
  },
  {
    id: 3692,
    description: 'Doubletree Hotels'
  },
  {
    id: 3693,
    description: 'Drury Inns'
  },
  {
    id: 3694,
    description: 'Economy Inns Of America'
  },
  {
    id: 3695,
    description: 'Embassy Suites'
  },
  {
    id: 3696,
    description: 'Exel Inns'
  },
  {
    id: 3697,
    description: 'Farfield Hotels'
  },
  {
    id: 3698,
    description: 'Harley Hotels'
  },
  {
    id: 3699,
    description: 'Midway Motor Lodge'
  },
  {
    id: 3700,
    description: 'Motel 6'
  },
  {
    id: 3701,
    description: 'Guest Quarters (Formally Pickett Suite Hotels)'
  },
  {
    id: 3702,
    description: 'The Registry Hotels'
  },
  {
    id: 3703,
    description: 'Residence Inns'
  },
  {
    id: 3704,
    description: 'Royce Hotels'
  },
  {
    id: 3705,
    description: 'Sandman Inns'
  },
  {
    id: 3706,
    description: 'Shilo Inns'
  },
  {
    id: 3707,
    description: 'Shoney\u2019S Inns'
  },
  {
    id: 3708,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3709,
    description: 'Super8 Motels'
  },
  {
    id: 3710,
    description: 'The Ritz Carlton Hotels'
  },
  {
    id: 3711,
    description: 'Flag Inns (Ausralia)'
  },
  {
    id: 3712,
    description: 'Golden Chain Hotel'
  },
  {
    id: 3713,
    description: 'Quality Pacific Hotel'
  },
  {
    id: 3714,
    description: 'Four Seasons Hotel (Australia)'
  },
  {
    id: 3715,
    description: 'Farifield Inn'
  },
  {
    id: 3716,
    description: 'Carlton Hotels'
  },
  {
    id: 3717,
    description: 'City Lodge Hotels'
  },
  {
    id: 3718,
    description: 'Karos Hotels'
  },
  {
    id: 3719,
    description: 'Protea Hotels'
  },
  {
    id: 3720,
    description: 'Southern Sun Hotels'
  },
  {
    id: 3721,
    description: 'Hilton Conrad'
  },
  {
    id: 3722,
    description: 'Wyndham Hotel And Resorts'
  },
  {
    id: 3723,
    description: 'Rica Hotels'
  },
  {
    id: 3724,
    description: 'Iner Nor Hotels'
  },
  {
    id: 3725,
    description: 'Seaines Planation'
  },
  {
    id: 3726,
    description: 'Rio Suites'
  },
  {
    id: 3727,
    description: 'Broadmoor Hotel'
  },
  {
    id: 3728,
    description: 'Bally\u2019S Hotel And Casino'
  },
  {
    id: 3729,
    description: 'John Ascuaga\u2019S Nugget'
  },
  {
    id: 3730,
    description: 'Mgm Grand Hotel'
  },
  {
    id: 3731,
    description: 'Harrah\u2019S Hotels And Casinos'
  },
  {
    id: 3732,
    description: 'Opryland Hotel'
  },
  {
    id: 3733,
    description: 'Boca Raton Resort'
  },
  {
    id: 3734,
    description: 'Harvey/bristol Hotels'
  },
  {
    id: 3735,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3736,
    description: 'Colorado Belle/edgewater Resort'
  },
  {
    id: 3737,
    description: 'Riviera Hotel And Casino'
  },
  {
    id: 3738,
    description: 'Tropicana Resort And Casino'
  },
  {
    id: 3739,
    description: 'Woodside Hotels And Resorts'
  },
  {
    id: 3740,
    description: 'Townplace Suites'
  },
  {
    id: 3741,
    description: 'Millenium Broadway Hotel'
  },
  {
    id: 3742,
    description: 'Club Med'
  },
  {
    id: 3743,
    description: 'Biltmore Hotel And Suites'
  },
  {
    id: 3744,
    description: 'Carefree Resorts'
  },
  {
    id: 3745,
    description: 'St. Regis Hotel'
  },
  {
    id: 3746,
    description: 'The Eliot Hotel'
  },
  {
    id: 3747,
    description: 'Clubcorp/club Resorts'
  },
  {
    id: 3748,
    description: 'Welesley Inns'
  },
  {
    id: 3749,
    description: 'The Beverly Hills Hotel'
  },
  {
    id: 3750,
    description: 'Crowne Plaza Hotels'
  },
  {
    id: 3751,
    description: 'Homewood Suites'
  },
  {
    id: 3752,
    description: 'Peabody Hotels'
  },
  {
    id: 3753,
    description: 'Greenbriah Resorts'
  },
  {
    id: 3754,
    description: 'Amelia Island Planation'
  },
  {
    id: 3755,
    description: 'The Homestead'
  },
  {
    id: 3756,
    description: 'South Seas Resorts'
  },
  {
    id: 3757,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3758,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3759,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3760,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3761,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3762,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3763,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3764,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3765,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3766,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3767,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3768,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3769,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3770,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3771,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3772,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3773,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3774,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3775,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3776,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3777,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3778,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3779,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3780,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3781,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3782,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3783,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3784,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3785,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3786,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3787,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3788,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3789,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3790,
    description: 'Hotels/motels/inns/resorts'
  },
  {
    id: 3835,
    description: '* Masters Economy Inns'
  },
  {
    id: 4011,
    description: 'Railroads'
  },
  {
    id: 4111,
    description: 'Local/suburban Commuter Passenger Transportation \u2013 Railroads, Feries, Local Water Transportation.'
  },
  {
    id: 4112,
    description: 'Passenger Railways'
  },
  {
    id: 4119,
    description: 'Ambulance Services'
  },
  {
    id: 4121,
    description: 'Taxicabs And Limousines'
  },
  {
    id: 4131,
    description: 'Bus Lines, Including Charters, Tour Buses'
  },
  {
    id: 4214,
    description: 'Motor Freight Carriers, Moving And Storage Companies, Trucking \u2013 Local/long Distance, Delivery Services \u2013 Local'
  },
  {
    id: 4215,
    description: 'Courier Services \u2013 Air Or Ground, Freight Forwarders'
  },
  {
    id: 4225,
    description: 'Public Warehousing, Storage'
  },
  {
    id: 4411,
    description: 'Cruise And Steamship Lines'
  },
  {
    id: 4457,
    description: 'Boat Rentals And Leases'
  },
  {
    id: 4468,
    description: 'Marinas, Marine Service, And Supplies'
  },
  {
    id: 4511,
    description: 'Airlines, Air Carriers ( Not Listed Elsewhere)'
  },
  {
    id: 4582,
    description: 'Airports, Airport Terminals, Flying Fields'
  },
  {
    id: 4722,
    description: 'Travel Agencies And Tour Operations'
  },
  {
    id: 4723,
    description: 'Package Tour Operators (For Use In Germany Only)'
  },
  {
    id: 4784,
    description: 'Toll And Bridge Fees'
  },
  {
    id: 4789,
    description: 'Transportation Services, Not Elsewhere Classified)'
  },
  {
    id: 4812,
    description: 'Telecommunications Equipment Including Telephone Sales'
  },
  {
    id: 4814,
    description: 'Fax Services, Telecommunication Services'
  },
  {
    id: 4815,
    description: 'Visaphone'
  },
  {
    id: 4816,
    description: 'Computer Network Services'
  },
  {
    id: 4821,
    description: 'Telegraph Services'
  },
  {
    id: 4829,
    description: 'Money Orders \u2013 Wire Transfer'
  },
  {
    id: 4899,
    description: 'Cable And Other Pay Television (Previously Cable Services)'
  },
  {
    id: 4900,
    description: 'Electric, Gas, Sanitary And Water Utilities'
  },
  {
    id: 5013,
    description: 'Motor Vehicle Supplies And New Parts'
  },
  {
    id: 5021,
    description: 'Office And Commercial Furniture'
  },
  {
    id: 5039,
    description: 'Construction Materials, Not Elsewhere Classified'
  },
  {
    id: 5044,
    description: 'Office, Photographic, Photocopy, And Microfilm Equipment'
  },
  {
    id: 5045,
    description: 'Computers, Computer Peripheral Equipment, Software'
  },
  {
    id: 5046,
    description: 'Commercial Equipment, Not Elsewhere Classified'
  },
  {
    id: 5047,
    description: 'Medical, Dental Ophthalmic, Hospital Equipment And Supplies'
  },
  {
    id: 5051,
    description: 'Metal Service Centers And Offices'
  },
  {
    id: 5065,
    description: 'Electrical Parts And Equipment'
  },
  {
    id: 5072,
    description: 'Hardware Equipment And Supplies'
  },
  {
    id: 5074,
    description: 'Plumbing And Heating Equipment And Supplies'
  },
  {
    id: 5085,
    description: 'Industrial Supplies, Not Elsewhere Classified'
  },
  {
    id: 5094,
    description: 'Precious Stones And Metals, Watches And Jewelry'
  },
  {
    id: 5099,
    description: 'Durable Goods, Not Elsewhere Classified'
  },
  {
    id: 5111,
    description: 'Stationery, Office Supplies, Printing, And Writing Paper'
  },
  {
    id: 5122,
    description: 'Drugs, Drug Proprietors, And Druggist\u2019S Sundries'
  },
  {
    id: 5131,
    description: 'Piece Goods, Notions, And Other Dry Goods'
  },
  {
    id: 5137,
    description: 'Men\u2019S Women\u2019S And Children\u2019S Uniforms And Commercial Clothing'
  },
  {
    id: 5139,
    description: 'Commercial Footwear'
  },
  {
    id: 5169,
    description: 'Chemicals And Allied Products, Not Elsewhere Classified'
  },
  {
    id: 5172,
    description: 'Petroleum And Petroleum Products'
  },
  {
    id: 5192,
    description: 'Books, Periodicals, And Newspapers'
  },
  {
    id: 5193,
    description: 'Florists\u2019 Supplies, Nursery Stock And Flowers'
  },
  {
    id: 5198,
    description: 'Paints, Varnishes, And Supplies'
  },
  {
    id: 5199,
    description: 'Non-Durable Goods, Not Elsewhere Classified'
  },
  {
    id: 5200,
    description: 'Home Supply Warehouse Stores'
  },
  {
    id: 5211,
    description: 'Lumber And Building Materials Stores'
  },
  {
    id: 5231,
    description: 'Glass, Paint, And Wallpaper Stores'
  },
  {
    id: 5251,
    description: 'Hardware Stores'
  },
  {
    id: 5261,
    description: 'Nurseries \u2013 Lawn And Garden Supply Store'
  },
  {
    id: 5271,
    description: 'Mobile Home Dealers'
  },
  {
    id: 5300,
    description: 'Wholesale Clubs'
  },
  {
    id: 5309,
    description: 'Duty Free Store'
  },
  {
    id: 5310,
    description: 'Discount Stores'
  },
  {
    id: 5311,
    description: 'Department Stores'
  },
  {
    id: 5331,
    description: 'Variety Stores'
  },
  {
    id: 5399,
    description: 'Misc. General Merchandise'
  },
  {
    id: 5411,
    description: 'Grocery Stores, Supermarkets'
  },
  {
    id: 5422,
    description: 'Meat Provisioners \u2013 Freezer And Locker'
  },
  {
    id: 5441,
    description: 'Candy, Nut, And Confectionery Stores'
  },
  {
    id: 5451,
    description: 'Dairy Products Stores'
  },
  {
    id: 5462,
    description: 'Bakeries'
  },
  {
    id: 5499,
    description: 'Misc. Food Stores \u2013 Convenience Stores And Specialty Markets'
  },
  {
    id: 5511,
    description: 'Car And Truck Dealers (New And Used) Sales, Service, Repairs, Parts, And Leasing'
  },
  {
    id: 5521,
    description: 'Automobile And Truck Dealers (Used Only)'
  },
  {
    id: 5531,
    description: 'Automobile Supply Stores'
  },
  {
    id: 5532,
    description: 'Automotive Tire Stores'
  },
  {
    id: 5533,
    description: 'Automotive Parts, Accessories Stores'
  },
  {
    id: 5541,
    description: 'Service Stations ( With Or Without Ancillary Services)'
  },
  {
    id: 5542,
    description: 'Automated Fuel Dispensers'
  },
  {
    id: 5551,
    description: 'Boat Dealers'
  },
  {
    id: 5561,
    description: 'Recreational And Utility Trailers, Camp Dealers'
  },
  {
    id: 5571,
    description: 'Motorcycle Dealers'
  },
  {
    id: 5592,
    description: 'Motor Home Dealers'
  },
  {
    id: 5598,
    description: 'Snowmobile Dealers'
  },
  {
    id: 5599,
    description: 'Miscellaneous Auto Dealers '
  },
  {
    id: 5611,
    description: 'Men\u2019S And Boy\u2019S Clothing And Accessories Stores'
  },
  {
    id: 5621,
    description: 'Women\u2019S Ready-To-Wear Stores'
  },
  {
    id: 5631,
    description: 'Women\u2019S Accessory And Specialty Shops'
  },
  {
    id: 5641,
    description: 'Children\u2019S And Infant\u2019S Wear Stores'
  },
  {
    id: 5651,
    description: 'Family Clothing Stores'
  },
  {
    id: 5655,
    description: 'Sports Apparel, Riding Apparel Stores'
  },
  {
    id: 5661,
    description: 'Shoe Stores'
  },
  {
    id: 5681,
    description: 'Furriers And Fur Shops'
  },
  {
    id: 5691,
    description: 'Men\u2019S And Women\u2019S Clothing Stores'
  },
  {
    id: 5697,
    description: 'Tailors, Seamstress, Mending, And Alterations'
  },
  {
    id: 5698,
    description: 'Wig And Toupee Stores'
  },
  {
    id: 5699,
    description: 'Miscellaneous Apparel And Accessory Shops'
  },
  {
    id: 5712,
    description: 'Furniture, Home Furnishings, And Equipment Stores, Exceptappliances'
  },
  {
    id: 5713,
    description: 'Floor Covering Stores'
  },
  {
    id: 5714,
    description: 'Drapery, Window Covering And Upholstery Stores'
  },
  {
    id: 5718,
    description: 'Fireplace, Fireplace Screens, And Accessories Stores'
  },
  {
    id: 5719,
    description: 'Miscellaneous Home Furnishing Specialty Stores'
  },
  {
    id: 5722,
    description: 'Household Appliance Stores'
  },
  {
    id: 5732,
    description: 'Electronic Sales'
  },
  {
    id: 5733,
    description: 'Music Stores, Musical Instruments, Piano Sheet Music'
  },
  {
    id: 5734,
    description: 'Computer Software Stores'
  },
  {
    id: 5735,
    description: 'Record Shops'
  },
  {
    id: 5811,
    description: 'Caterers'
  },
  {
    id: 5812,
    description: 'Eating Places And Restaurants'
  },
  {
    id: 5813,
    description: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail Lounges, Nightclubs And Discotheques'
  },
  {
    id: 5814,
    description: 'Fast Food Restaurants'
  },
  {
    id: 5815,
    description: 'Digital Media, Books, Movies, Music'
  },
  {
    id: 5816,
    description: 'Digital Games Digital Goods - Games'
  },
  {
    id: 5817,
    description: 'Digital Applications Digital Goods - Applications (Excludes Games)'
  },
  {
    id: 5818,
    description: 'Digital Goods - Large Digital Goods Merchant'
  },
  {
    id: 5832,
    description: 'Antique Shops \u2013 Sales, Repairs, And Restoration Services'
  },
  {
    id: 5912,
    description: 'Drug Stores And Pharmacies'
  },
  {
    id: 5921,
    description: 'Package Stores \u2013 Beer, Wine, And Liquor'
  },
  {
    id: 5931,
    description: 'Used Merchandise And Secondhand Stores'
  },
  {
    id: 5932,
    description: 'Antique Shops'
  },
  {
    id: 5933,
    description: 'Pawn Shops And Salvage Yards'
  },
  {
    id: 5935,
    description: 'Wrecking And Salvage Yards'
  },
  {
    id: 5937,
    description: 'Antique Reproductions'
  },
  {
    id: 5940,
    description: 'Bicycle Shops \u2013 Sales And Service'
  },
  {
    id: 5941,
    description: 'Sporting Goods Stores'
  },
  {
    id: 5942,
    description: 'Book Stores'
  },
  {
    id: 5943,
    description: 'Stationery Stores, Office And School Supply Stores'
  },
  {
    id: 5944,
    description: 'Watch, Clock, Jewelry, And Silverware Stores'
  },
  {
    id: 5945,
    description: 'Hobby, Toy, And Game Shops'
  },
  {
    id: 5946,
    description: 'Camera And Photographic Supply Stores'
  },
  {
    id: 5947,
    description: 'Card Shops, Gift, Novelty, And Souvenir Shops'
  },
  {
    id: 5948,
    description: 'Leather Foods Stores'
  },
  {
    id: 5949,
    description: 'Sewing, Needle, Fabric, And Price Goods Stores'
  },
  {
    id: 5950,
    description: 'Glassware/crystal Stores'
  },
  {
    id: 5960,
    description: 'Direct Marketing- Insurance Service'
  },
  {
    id: 5961,
    description: 'Mail Order Houses Including Catalog Order Stores, Book/record Clubs (No Longer Permitted For U.s. Original Presentments)'
  },
  {
    id: 5962,
    description: 'Direct Marketing \u2013 Travel Related Arrangements Services'
  },
  {
    id: 5963,
    description: 'Door-To-Door Sales'
  },
  {
    id: 5964,
    description: 'Direct Marketing \u2013 Catalog Merchant'
  },
  {
    id: 5965,
    description: 'Direct Marketing \u2013 Catalog And Catalog And Retail Merchant'
  },
  {
    id: 5966,
    description: 'Direct Marketing- Outbound Telemarketing Merchant'
  },
  {
    id: 5967,
    description: 'Direct Marketing \u2013 Inbound Teleservices Merchant'
  },
  {
    id: 5968,
    description: 'Direct Marketing \u2013 Continuity/subscription Merchant'
  },
  {
    id: 5969,
    description: 'Direct Marketing \u2013 Not Elsewhere Classified'
  },
  {
    id: 5970,
    description: 'Artist\u2019S Supply And Craft Shops'
  },
  {
    id: 5971,
    description: 'Art Dealers And Galleries'
  },
  {
    id: 5972,
    description: 'Stamp And Coin Stores \u2013 Philatelic And Numismatic Supplies'
  },
  {
    id: 5973,
    description: 'Religious Goods Stores'
  },
  {
    id: 5975,
    description: 'Hearing Aids \u2013 Sales, Service, And Supply Stores'
  },
  {
    id: 5976,
    description: 'Orthopedic Goods Prosthetic Devices'
  },
  {
    id: 5977,
    description: 'Cosmetic Stores'
  },
  {
    id: 5978,
    description: 'Typewriter Stores \u2013 Sales, Rental, Service'
  },
  {
    id: 5983,
    description: 'Fuel \u2013 Fuel Oil, Wood, Coal, Liquefied Petroleum'
  },
  {
    id: 5992,
    description: 'Florists'
  },
  {
    id: 5993,
    description: 'Cigar Stores And Stands'
  },
  {
    id: 5994,
    description: 'News Dealers And Newsstands'
  },
  {
    id: 5995,
    description: 'Pet Shops, Pet Foods, And Supplies Stores'
  },
  {
    id: 5996,
    description: 'Swimming Pools \u2013 Sales, Service, And Supplies'
  },
  {
    id: 5997,
    description: 'Electric Razor Stores \u2013 Sales And Service'
  },
  {
    id: 5998,
    description: 'Tent And Awning Shops'
  },
  {
    id: 5999,
    description: 'Miscellaneous And Specialty Retail Stores'
  },
  {
    id: 6010,
    description: 'Financial Institutions \u2013 Manual Cash Disbursements'
  },
  {
    id: 6011,
    description: 'Financial Institutions \u2013 Automated Cash Disbursements'
  },
  {
    id: 6012,
    description: 'Financial Institutions \u2013 Merchandise And Services'
  },
  {
    id: 6051,
    description: 'Non-Financial Institutions \u2013 Foreign Currency, Money Orders (Not Wire Transfer) And Travelers Cheques'
  },
  {
    id: 6211,
    description: 'Security Brokers/dealers'
  },
  {
    id: 6300,
    description: 'Insurance Sales, Underwriting, And Premiums'
  },
  {
    id: 6381,
    description: 'Insurance Premiums, (No Longer Valid For First Presentment Work)'
  },
  {
    id: 6399,
    description: 'Insurance, Not Elsewhere Classified ( No Longer Valid Forfirst Presentment Work)'
  },
  {
    id: 6513,
    description: 'Real Estate Agents And Managers - Rentals'
  },
  {
    id: 6540,
    description: 'Non-financial Institutions - Stored Value Card Purchase/Load'
  },
  {
    id: 7011,
    description: 'Lodging \u2013 Hotels, Motels, Resorts, Central Reservation Services (Not Elsewhere Classified)'
  },
  {
    id: 7012,
    description: 'Timeshares'
  },
  {
    id: 7032,
    description: 'Sporting And Recreational Camps'
  },
  {
    id: 7033,
    description: 'Trailer Parks And Camp Grounds'
  },
  {
    id: 7210,
    description: 'Laundry, Cleaning, And Garment Services'
  },
  {
    id: 7211,
    description: 'Laundry \u2013 Family And Commercial'
  },
  {
    id: 7216,
    description: 'Dry Cleaners'
  },
  {
    id: 7217,
    description: 'Carpet And Upholstery Cleaning'
  },
  {
    id: 7221,
    description: 'Photographic Studios'
  },
  {
    id: 7230,
    description: 'Barber And Beauty Shops'
  },
  {
    id: 7251,
    description: 'Shop Repair Shops And Shoe Shine Parlors, And Hat Cleaning Shops'
  },
  {
    id: 7261,
    description: 'Funeral Service And Crematories'
  },
  {
    id: 7273,
    description: 'Dating And Escort Services'
  },
  {
    id: 7276,
    description: 'Tax Preparation Service'
  },
  {
    id: 7277,
    description: 'Counseling Service \u2013 Debt, Marriage, Personal'
  },
  {
    id: 7278,
    description: 'Buying/shopping Services, Clubs'
  },
  {
    id: 7296,
    description: 'Clothing Rental \u2013 Costumes, Formal Wear, Uniforms'
  },
  {
    id: 7297,
    description: 'Massage Parlors'
  },
  {
    id: 7298,
    description: 'Health And Beauty Shops'
  },
  {
    id: 7299,
    description: 'Miscellaneous Personal Services ( Not Elsewhere Classifies)'
  },
  {
    id: 7311,
    description: 'Advertising Services'
  },
  {
    id: 7321,
    description: 'Consumer Credit Reporting Agencies'
  },
  {
    id: 7332,
    description: 'Blueprinting And Photocopying Services'
  },
  {
    id: 7333,
    description: 'Commercial Photography, Art And Graphics'
  },
  {
    id: 7338,
    description: 'Quick Copy, Reproduction And Blueprinting Services'
  },
  {
    id: 7339,
    description: 'Stenographic And Secretarial Support Services'
  },
  {
    id: 7342,
    description: 'Exterminating And Disinfecting Services'
  },
  {
    id: 7349,
    description: 'Cleaning And Maintenance, Janitorial Services'
  },
  {
    id: 7361,
    description: 'Employment Agencies, Temporary Help Services'
  },
  {
    id: 7372,
    description: 'Computer Programming, Integrated Systems Design And Data Processing Services'
  },
  {
    id: 7375,
    description: 'Information Retrieval Services'
  },
  {
    id: 7379,
    description: 'Computer Maintenance And Repair Services, Not Elsewhere Classified'
  },
  {
    id: 7392,
    description: 'Management, Consulting, And Public Relations Services'
  },
  {
    id: 7393,
    description: 'Protective And Security Services \u2013 Including Armored Cars and Guard Dogs'
  },
  {
    id: 7394,
    description: 'Equipment Rental And Leasing Services, Tool Rental, Furniture Rental, And Appliance Rental'
  },
  {
    id: 7395,
    description: 'Photofinishing Laboratories, Photo Developing'
  },
  {
    id: 7399,
    description: 'Business Services, Not Elsewhere Classified'
  },
  {
    id: 7511,
    description: 'Truck Stop'
  },
  {
    id: 7512,
    description: 'Car Rental Companies ( Not Listed Below)'
  },
  {
    id: 7513,
    description: 'Truck And Utility Trailer Rentals'
  },
  {
    id: 7519,
    description: 'Motor Home And Recreational Vehicle Rentals'
  },
  {
    id: 7523,
    description: 'Automobile Parking Lots And Garages'
  },
  {
    id: 7531,
    description: 'Automotive Body Repair Shops'
  },
  {
    id: 7534,
    description: 'Tire Re-Treading And Repair Shops'
  },
  {
    id: 7535,
    description: 'Paint Shops \u2013 Automotive'
  },
  {
    id: 7538,
    description: 'Automotive Service Shops'
  },
  {
    id: 7542,
    description: 'Car Washes'
  },
  {
    id: 7549,
    description: 'Towing Services'
  },
  {
    id: 7622,
    description: 'Radio Repair Shops'
  },
  {
    id: 7623,
    description: 'Air Conditioning And Refrigeration Repair Shops'
  },
  {
    id: 7629,
    description: 'Electrical And Small Appliance Repair Shops'
  },
  {
    id: 7631,
    description: 'Watch, Clock, And Jewelry Repair'
  },
  {
    id: 7641,
    description: 'Furniture, Furniture Repair, And Furniture Refinishing'
  },
  {
    id: 7692,
    description: 'Welding Repair'
  },
  {
    id: 7699,
    description: 'Repair Shops And Related Services \u2013Miscellaneous'
  },
  {
    id: 7800,
    description: 'Gambling - Government-Owned Lotteries'
  },
  {
    id: 7801,
    description: 'Gambling - Government-Licensed On-Line Casinos'
  },
  {
    id: 7802,
    description: 'Gambling - Government-Licensed Horse/Dog racing'
  },
  {
    id: 7829,
    description: 'Motion Pictures And Video Tape Production And Distribution'
  },
  {
    id: 7832,
    description: 'Motion Picture Theaters'
  },
  {
    id: 7841,
    description: 'Video Tape Rental Stores'
  },
  {
    id: 7911,
    description: 'Dance Halls, Studios And Schools'
  },
  {
    id: 7922,
    description: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies'
  },
  {
    id: 7929,
    description: 'Bands, Orchestras, And Miscellaneous Entertainers (Not Elsewhere Classified)'
  },
  {
    id: 7932,
    description: 'Billiard And Pool Establishments'
  },
  {
    id: 7933,
    description: 'Bowling Alleys'
  },
  {
    id: 7941,
    description: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, And Sport Promoters'
  },
  {
    id: 7991,
    description: 'Tourist Attractions And Exhibits'
  },
  {
    id: 7992,
    description: 'Golf Courses \u2013 Public'
  },
  {
    id: 7993,
    description: 'Video Amusement Game Supplies'
  },
  {
    id: 7994,
    description: 'Video Game Arcades/establishments'
  },
  {
    id: 7995,
    description: 'Betting (Including Lottery Tickets, Casino Gaming Chips, Off-Track Betting And Wagers)'
  },
  {
    id: 7996,
    description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers'
  },
  {
    id: 7997,
    description: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, And Private Golf Courses'
  },
  {
    id: 7998,
    description: 'Aquariums, Sea-Aquariums, Dolphinariums'
  },
  {
    id: 7999,
    description: 'Recreation Services (Not Elsewhere Classified)'
  },
  {
    id: 8011,
    description: 'Doctors And Physicians (Not Elsewhere Classified)'
  },
  {
    id: 8021,
    description: 'Dentists And Orthodontists'
  },
  {
    id: 8031,
    description: 'Osteopaths'
  },
  {
    id: 8041,
    description: 'Chiropractors'
  },
  {
    id: 8042,
    description: 'Optometrists And Ophthalmologists'
  },
  {
    id: 8043,
    description: 'Opticians, Opticians Goods And Eyeglasses'
  },
  {
    id: 8044,
    description: 'Opticians, Optical Goods, And Eyeglasses (No Longer Valid for First Presentments)'
  },
  {
    id: 8049,
    description: 'Podiatrists And Chiropodists'
  },
  {
    id: 8050,
    description: 'Nursing And Personal Care Facilities'
  },
  {
    id: 8062,
    description: 'Hospitals'
  },
  {
    id: 8071,
    description: 'Medical And Dental Laboratories'
  },
  {
    id: 8099,
    description: 'Medical Services And Health Practitioners (Not Elsewhere Classified)'
  },
  {
    id: 8111,
    description: 'Legal Services And Attorneys'
  },
  {
    id: 8211,
    description: 'Elementary And Secondary Schools'
  },
  {
    id: 8220,
    description: 'Colleges, Junior Colleges, Universities, And Professional schools'
  },
  {
    id: 8241,
    description: 'Correspondence Schools'
  },
  {
    id: 8244,
    description: 'Business And Secretarial Schools'
  },
  {
    id: 8249,
    description: 'Vocational Schools And Trade Schools'
  },
  {
    id: 8299,
    description: 'Schools And Educational Services ( Not Elsewhere Classified)'
  },
  {
    id: 8351,
    description: 'Child Care Services'
  },
  {
    id: 8398,
    description: 'Charitable And Social Service Organizations'
  },
  {
    id: 8641,
    description: 'Civic, Fraternal, And Social Associations'
  },
  {
    id: 8651,
    description: 'Political Organizations'
  },
  {
    id: 8661,
    description: 'Religious Organizations'
  },
  {
    id: 8675,
    description: 'Automobile Associations'
  },
  {
    id: 8699,
    description: 'Membership Organizations ( Not Elsewhere Classified)'
  },
  {
    id: 8734,
    description: 'Testing Laboratories ( Non-Medical)'
  },
  {
    id: 8911,
    description: 'Architectural \u2013 Engineering And Surveying Services'
  },
  {
    id: 8931,
    description: 'Accounting, Auditing, And Bookkeeping Services'
  },
  {
    id: 8999,
    description: 'Professional Services ( Not Elsewhere Defined)'
  },
  {
    id: 9211,
    description: 'Court Costs, Including Alimony And Child Support'
  },
  {
    id: 9222,
    description: 'Fines'
  },
  {
    id: 9223,
    description: 'Bail And Bond Payments'
  },
  {
    id: 9311,
    description: 'Tax Payments'
  },
  {
    id: 9399,
    description: 'Government Services ( Not Elsewhere Classified)'
  },
  {
    id: 9402,
    description: 'Postal Services \u2013 Government Only'
  },
  {
    id: 9405,
    description: 'Intra \u2013 Government Transactions'
  },
  {
    id: 9700,
    description: 'Automated Referral Service ( For Visa Only)'
  },
  {
    id: 9701,
    description: 'Visa Credential Service ( For Visa Only)'
  },
  {
    id: 9702,
    description: 'Gcas Emergency Services ( For Visa Only)'
  },
  {
    id: 9950,
    description: 'Intra \u2013 Company Purchases ( For Visa Only)'
  }
]

export function getDescriptionById (id) {
  const code = codes.find(item => item.id === id)
  if (code) {
    return code.description
  }

  return null
}
