import api from './api'
import TokenService from './token'

class AuthorizationService {
  login (email, password) {
    return api
      .post('/login', {
        Email: email,
        Password: password
      })
      .then(response => {
        if (response.data.Token) {
          TokenService.setUser(response.data)
        }

        return response.data
      })
  }

  logout () {
    TokenService.removeUser()
  }

  register (username, email, password) {
    // TBD
  }

  getCurrentUser () {
    return TokenService.getUser()
  }
}

export default new AuthorizationService()
