import React, { useState, useEffect } from 'react'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { IconButton, DefaultButton } from '@fluentui/react/lib/Button'
import { getTheme, Stack, Callout, ColorPicker, Shimmer, ShimmerElementType, ShimmerElementsGroup, getColorFromString } from '@fluentui/react'
import { NeutralColors, FontSizes } from '@fluentui/theme'

import { useBoolean, useId } from '@fluentui/react-hooks'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import CategoriesService from './services/categories'
import { TextField } from '@fluentui/react/lib/TextField'
import { v4 as uuid } from 'uuid'

export const categoriesList = [
  {
    Name: 'Healthcare',
    Color: '#ff0000',
    Id: 0
  },
  {
    Name: 'Food',
    Color: '#00ff00',
    Id: 1
  },
  {
    Name: 'Transport',
    Color: '#0000ff',
    Id: 2
  },
  {
    Name: 'Sport',
    Color: '#ffff00',
    Id: 3
  }
]

function ColorPickerIcon (props) {
  const [isColorPickerVisible, { toggle: toggleIsColorPickerVisible }] = useBoolean(false)
  const [color, setColor] = useState(props.color)

  const colorPickerClass = mergeStyles({
    background: props.color,
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    cursor: 'pointer'
  })

  const id = useId('color-picker-icon-' + uuid())

  const updateColor = React.useCallback((ev, colorObj) => {
    setColor(colorObj.str)
  }, [])

  return (
    <div>
      <div id={id} className={colorPickerClass} onClick={toggleIsColorPickerVisible}></div>
      {isColorPickerVisible && (
        <Callout
          role="dialog"
          gapSpace={0}
          target={`#${id}`}
          onDismiss={toggleIsColorPickerVisible}
        >
          <ColorPicker
            color={getColorFromString(color)}
            alphaType={'none'}
            showPreview={true}
            onChange={updateColor}
          />
          {props.handleSave && <DefaultButton text="Save" onClick={() => props.handleSave(color)} />}
        </Callout>
      )}
    </div>
  )
}

export const ItemTypes = {
  Category: 'category'
}

function Category (props) {
  const [isHovering, { toggle: toggleIsHovering }] = useBoolean(false)
  const [acceptsItem, setAcceptsItem] = useState(true)
  const [categoryName, setCategoryName] = useState(props.name)
  const [isInEditMode, { toggle: toggleIsInEditMode }] = useBoolean(false)
  const [color, setColor] = useState(props.color)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'category',
    drag (item, monitor) {
    },
    item: () => {
      return { id: props.id, name: props.name, color: props.color, parent: props.parent, children: props.children }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'category',
      drop: (item, monitor) => {
        props.updateParent(item, { id: props.id, name: props.name, color: props.color, parent: props.parent, children: props.children })
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      }),
      canDrop (item, monitor) {
        const canUpdateParent = props.canUpdateParent(props.id, item.id)
        setAcceptsItem(canUpdateParent)
        return canUpdateParent
      }
    })
  )

  const theme = getTheme()

  const categoryNameClass = mergeStyles({
    width: '500px',
    height: '60px',
    padding: '20px',
    backgroundColor: isDragging ? theme.palette.themeLight : theme.palette.white,
    boxShadow: theme.effects.elevation4,
    border: 'solid',
    borderWidth: (isOver && acceptsItem) ? '3px' : 0,
    borderColor: isOver ? theme.palette.themePrimary : theme.palette.white,
    cursor: 'move'
  })

  const nameClass = mergeStyles({
    color: NeutralColors.gray150,
    fontSize: FontSizes.size16
  })

  const groupMarginClass = mergeStyles({
    marginLeft: `${props.group * 50}px`
  })

  const attachRef = (element) => {
    drag(element)
    drop(element)
  }

  const deleteCategory = () => {
    CategoriesService.delete(props.id)
      .then(() => {
        props.triggerRefresh()
      })
  }

  const handleNameFieldChange = (event) => {
    setCategoryName(event.target.value)
  }

  const saveName = () => {
    CategoriesService.update(props.id, categoryName, color, props.parent, props.children, false).then((response) => {
      if (response.status === 204) {
        props.triggerRefresh()
      }
    })
  }

  const saveColor = (color) => {
    CategoriesService.update(props.id, props.name, color, props.parent, props.children, false).then((response) => {
      if (response.status === 204) {
        props.triggerRefresh()
      }
    })
  }

  const moveToRoot = () => {
    CategoriesService.update(props.id, props.name, color, null, props.children, false).then(() => {
      props.triggerRefresh()
    })
  }

  return (
    <div ref={attachRef} draggable="true" className={groupMarginClass}>
      <Stack
        className={categoryNameClass}
        horizontal
        horizontalAlign='space-between'
        onMouseOver={toggleIsHovering}
        onMouseOut={toggleIsHovering}
        draggable
      >
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
          <Stack.Item><ColorPickerIcon id={props.id} color={props.color} onChange={setColor} handleSave={saveColor} /></Stack.Item>
          <Stack.Item className={nameClass}>
            {isInEditMode && <TextField name='CategoryName' underlined defaultValue={props.name} onChange={handleNameFieldChange} />}
            {!isInEditMode && props.name}
          </Stack.Item>
        </Stack>
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
          {isHovering &&
            <div>
              <IconButton iconProps={{ iconName: isInEditMode ? 'Save' : 'Edit' }} onClick={isInEditMode ? saveName : toggleIsInEditMode} />
              <IconButton iconProps={{ iconName: 'PlugDisconnected' }} onClick={moveToRoot} />
              <IconButton iconProps={{ iconName: 'Cancel' }} onClick={deleteCategory} />
            </div>
          }
        </Stack>
      </Stack>
    </div>
  )
}

function AddCategory (props) {
  const theme = getTheme()
  const [name, setName] = useState(null)
  const [color, setColor] = useState(theme.palette.themePrimary)

  const categoryNameClass = mergeStyles({
    width: '500px',
    height: '60px',
    padding: '20px',
    backgroundColor: theme.palette.white,
    boxShadow: theme.effects.elevation4,
    marginBottom: 30
  })

  const nameClass = mergeStyles({
    color: NeutralColors.gray150,
    fontSize: FontSizes.size16
  })

  const handleInputChange = (value) => {
    setName(value)
  }

  const handleButtonClick = () => {
    CategoriesService.create(name, color, null, [], true)
    props.triggerRefresh()
  }

  return (

    <Stack
      className={categoryNameClass}
      horizontal
      horizontalAlign='space-between'
    >
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
        <Stack.Item><ColorPickerIcon id={'new-category'} color={color} handleSave={setColor} /></Stack.Item>
        <Stack.Item className={nameClass}><TextField label="Name:" underlined onChange={(e) => handleInputChange(e.target.value)} /></Stack.Item>
      </Stack>
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
        <IconButton iconProps={{ iconName: 'Add' }} onClick={handleButtonClick} />
      </Stack>
    </Stack>

  )
}

export function Categories (props) {
  const [data, setData] = useState(null)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    CategoriesService.getAll().then((data) => {
      setData(data)
      setRefresh(false)
    })
  }, [refresh])

  const updateParent = (item, newParent) => {
    newParent.children.push(item.id)
    CategoriesService.update(item.id, item.name, item.color, newParent.id, item.children, false).then((data) => {
      triggerRefresh()
    })
  }
  const canUpdateParent = (parentCandidateId, id) => {
    if (parentCandidateId === id) {
      return false
    }

    let isDirectChild = false
    let cyclicDependecy = false
    let group = null
    let canClearGroup = false
    data.forEach((category) => {
      if (id === category.Id && parentCandidateId === category.ParentId) {
        isDirectChild = true
      }

      if (id === category.Id) {
        group = category.Group
      }

      if (group !== null && parentCandidateId === category.Id && category.Group > group) {
        cyclicDependecy = true
      }

      if (canClearGroup && category.Group <= group) {
        group = null
      }

      if (id === category.Id) {
        canClearGroup = true
      }
    })

    return !cyclicDependecy && !isDirectChild
  }

  const triggerRefresh = () => {
    setData(null)
    setRefresh(true)
  }

  if (data === null) {
    return (
      <Stack vertical tokens={{ childrenGap: 10 }}>
        <AddCategory triggerRefresh={triggerRefresh} />
        <div style={{ marginLeft: 0 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
        <div style={{ marginLeft: 50 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
        <div style={{ marginLeft: 100 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
        <div style={{ marginLeft: 0 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
        <div style={{ marginLeft: 50 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
        <div style={{ marginLeft: 0 }}>
          <Shimmer width="500px" customElementsGroup={
            <ShimmerElementsGroup
              backgroundColor={NeutralColors.gray10}
              shimmerElements={[
                { type: ShimmerElementType.line, height: 50 }
              ]}
            />
          } />
        </div>
      </Stack>
    )
  }

  const listItems = data.map((category, index) =>
    <Category key={index} triggerRefresh={triggerRefresh} id={category.Id} name={category.Name} color={category.Color} group={category.Group} parent={category.ParentId} children={category.ChildrenIds} updateParent={updateParent} canUpdateParent={canUpdateParent} expandingDisabled={!(category.ChildrenIds.length > 0)} />
  )

  return (
    <div>
      <AddCategory triggerRefresh={triggerRefresh} />
      <DndProvider backend={HTML5Backend}>
        <Stack tokens={{ childrenGap: 10 }}>
          {data.length === 0 &&
            'No data'
          }
          {data.length !== 0 && listItems}
        </Stack>
      </DndProvider>
    </div>
  )
}
